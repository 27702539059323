import { updateUser } from "@/api/user";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { UserContext } from "@/context/user-context";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const formSchema = z.object({
  currentPassword: z.string().min(1, { message: "Password is required" }),
  newPassword: z
    .string()
    .min(6, { message: "Password must be at least 6 characters" }),
});

const PasswordTab = () => {
  const { currentUser } = useContext(UserContext);

  const form = useForm({
    resolver: zodResolver(formSchema),
  });

  const updatePasswordMutation = useMutation({
    mutationFn: updateUser,
    onSuccess: () => {
      toast.success("Password updated successfully");
    },
    onError: (err: any) => {
      toast.error(err.message);
    },
  });

  const onSubmit = (data: any) => {
    updatePasswordMutation.mutate(data);
  };

  return (
    <div className="mx-auto rounded-md bg-white p-6 shadow-md sm:w-1/2">
      <p className="mb-6 text-lg font-semibold">Update Password</p>
      {currentUser?.source === "local" ? (
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="currentPassword"
              render={({ field }) => (
                <FormItem>
                  <label>Current Password</label>
                  <FormControl>
                    <Input
                      placeholder="Current Password"
                      {...field}
                      type="password"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="newPassword"
              render={({ field }) => (
                <FormItem>
                  <label>New Password</label>
                  <FormControl>
                    <Input
                      placeholder="New Password"
                      {...field}
                      type="password"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              type="submit"
              disabled={updatePasswordMutation.isPending}
              loading={updatePasswordMutation.isPending}
              className="w-40"
            >
              Update Password
            </Button>
          </form>
        </Form>
      ) : (
        <p className="text-sm text-gray-500">
          Account is connected with google
        </p>
      )}
    </div>
  );
};

export default PasswordTab;
