import React, { useEffect } from "react";
import { ReminderConfiguration } from "@/lib/constants/interface";
import {
  useMutation,
  useQuery,
  UseMutationResult,
} from "@tanstack/react-query";
import {
  getReminderConfiguration,
  updateReminderConfiguration,
} from "@/api/reminder-configuration";
import { toast } from "sonner";
import { useLocalStorage } from "@/hooks/use-local-storage";

interface EmailReminderContextType {
  reminderConfiguration: ReminderConfiguration | null;
  setReminderConfiguration: (
    reminderConfiguration: ReminderConfiguration,
  ) => void;
  updateReminderMutation: UseMutationResult<any, any, any, any>;
}

// Provide default values for the context
const defaultEmailReminderContext: EmailReminderContextType = {
  reminderConfiguration: null,
  setReminderConfiguration: () => {}, // Correct default value
  updateReminderMutation: {} as UseMutationResult<any, any, any, any>, // Default value
};

// Create the context
export const EmailReminderContext =
  React.createContext<EmailReminderContextType>(defaultEmailReminderContext);

const EmailReminderProvider = ({ children }: { children: React.ReactNode }) => {
  const [reminderConfiguration, setReminderConfiguration] = useLocalStorage(
    "reminder-configuration",
    null,
  );

  const { data } = useQuery({
    queryKey: ["reminder-configuration"],
    queryFn: getReminderConfiguration,
    refetchOnMount: false,
  });

  const updateReminderMutation = useMutation({
    mutationFn: updateReminderConfiguration,
    onSuccess: (res: any) => {
      setReminderConfiguration(res);
      toast.success("Reminder configuration updated successfully");
    },
    onError: (error: any) => {
      console.log(error);
    },
  });

  useEffect(() => {
    if (data) {
      //@ts-ignore
      setReminderConfiguration(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const value = {
    reminderConfiguration,
    setReminderConfiguration,
    updateReminderMutation,
  };

  return (
    <>
      <EmailReminderContext.Provider value={value}>
        {children}
      </EmailReminderContext.Provider>
    </>
  );
};

export default EmailReminderProvider;
