import GreetingIcon from "@/assets/icons/greeting.svg";
import { UserContext } from "@/context/user-context";
import { toTitleCase } from "@/utils/string-helpers";
import { useContext, useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";

const HomeCards = ({ data }: { data: any }) => {
  const [greeting, setGreeting] = useState("morning");

  const { currentUser } = useContext(UserContext);

  useEffect(() => {
    const date = new Date();
    const hours = date.getHours();

    if (hours >= 5 && hours <= 11) {
      setGreeting("morning");
    } else if (hours >= 12 && hours <= 16) {
      setGreeting("afternoon");
    } else {
      setGreeting("evening");
    }
  }, []);

  return (
    <div className="grid grid-cols-12 gap-4 2xl:grid-cols-10">
      <div className="relative col-span-12 h-44 rounded-xl border lg:col-span-6 2xl:col-span-2">
        <div className="flex flex-col gap-1 p-5 pb-10">
          <p className="text-xl">Good {toTitleCase(greeting)} 🌤</p>
          <p className="text-xl">{currentUser?.username}</p>

          <p className="mt-1 w-[60%] text-sm">
            Here are your insights on outstanding amounts
          </p>
        </div>
        <img
          src={GreetingIcon}
          alt="Greetings"
          className="absolute bottom-0 right-0 w-32"
        />
      </div>
      <div className="relative col-span-12 h-44 rounded-xl border md:col-span-6 lg:col-span-6 2xl:col-span-2">
        <div className="flex h-full flex-col items-center justify-center gap-1 p-5">
          <p className="text-xl">Total Outstanding Amount</p>
          <CurrencyFormat
            displayType={"text"}
            thousandSeparator={true}
            value={data.totalOutstandingAmount}
            thousandSpacing="2s"
            prefix={"₹"}
            renderText={(value) => (
              <p className="text-3xl font-semibold">{value}</p>
            )}
          />
        </div>
      </div>
      <div className="relative col-span-12 h-44 rounded-xl border md:col-span-6 lg:col-span-4 2xl:col-span-2">
        <div className="flex h-full flex-col items-center justify-center gap-1 p-5">
          <p className="text-xl">Total Customers</p>
          <p className="text-3xl font-semibold">{data.totalCustomers}</p>
        </div>
      </div>
      <div className="relative col-span-12 h-44 rounded-xl border md:col-span-6 lg:col-span-4 2xl:col-span-2">
        <div className="flex h-full flex-col items-center justify-center gap-1 p-5">
          <p className="text-xl">Avg. outstanding amount</p>
          <CurrencyFormat
            displayType={"text"}
            thousandSeparator={true}
            value={data.averageOutstandingAmount ?? 0}
            thousandSpacing="2s"
            prefix={"₹"}
            renderText={(value) => (
              <p className="text-3xl font-semibold">{value}</p>
            )}
          />
        </div>
      </div>
      <div className="relative col-span-12 h-44 rounded-xl border md:col-span-6 lg:col-span-4 2xl:col-span-2">
        <div className="flex h-full flex-col items-center justify-center gap-1 p-5">
          <p className="text-xl">No. of invoices outstanding</p>
          <p className="text-3xl font-semibold">{data.outstandingInvoices}</p>
        </div>
      </div>
    </div>
  );
};

export default HomeCards;
