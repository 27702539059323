import moment from "moment";

export const toTitleCase = (sentence: string) => {
  return sentence
    .toLowerCase() // Convert entire sentence to lowercase
    .split(" ") // Split sentence into words
    .map((word) =>
      word === "gst" || word === "pan"
        ? word.toUpperCase()
        : word.charAt(0).toUpperCase() + word.slice(1),
    ) // Capitalize first letter of each word
    .join(" "); // Join words back into a single string
};

export const camelCaseToSpaces = (str: string) => {
  return toTitleCase(
    str.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/([a-zA-Z])(\d)/g, "$1 $2"),
  );
};

export const isValidDate = (value: any) => {
  if (typeof value !== "string" || !isNaN(Number(value))) {
    return false;
  }
  const date = moment(value, moment.ISO_8601, true);

  // Check if the date is valid and realistic
  if (!date.isValid()) {
    return false;
  }

  const year = date.year();

  // Ensure the year is within a realistic range
  if (year < 1900 || year > new Date().getFullYear() + 100) {
    return false;
  }

  return true;
};
