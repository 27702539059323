import * as React from "react";
import * as ProgressPrimitive from "@radix-ui/react-progress";

import { cn } from "@/lib/utils";

// Define the custom props interface
interface ProgressProps
  extends React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> {
  customColor?: string; // Example custom prop for color
  showValue?: boolean; // Example custom prop for showing value
}

const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  ProgressProps
>(({ className, value, customColor, showValue, ...props }, ref) => (
  <ProgressPrimitive.Root
    ref={ref}
    className={cn(
      "relative h-4 w-full overflow-hidden rounded-full bg-secondary",
      className,
    )}
    {...props}
  >
    <ProgressPrimitive.Indicator
      className="flex h-full w-full flex-1 items-center justify-end rounded-full"
      style={{
        backgroundColor: customColor || "var(--primary-foreground)", // Use custom color or fallback to default
        transform: `translateX(-${100 - (value || 0)}%)`,
      }}
    >
      {showValue && <p className="px-2 text-xs text-white">{value}%</p>}
    </ProgressPrimitive.Indicator>
  </ProgressPrimitive.Root>
));

Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
