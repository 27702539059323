import React from "react";
import { User } from "@/utils/constants";
import { useLocalStorage } from "@/hooks/use-local-storage";

interface UserContextType {
  currentUser: User | null;
  setCurrentUser: (user: User | null) => void;
}

// Provide default values for the context
const defaultUserContext: UserContextType = {
  currentUser: null,
  setCurrentUser: () => {}, // Correct default value
};

// Create the context
export const UserContext =
  React.createContext<UserContextType>(defaultUserContext);

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentUser, setCurrentUser] = useLocalStorage("user", null);

  const value = {
    currentUser,
    setCurrentUser,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;
