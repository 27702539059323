export const sidebarSheetsRoutes = [
  {
    name: "Dashboard",
    path: "/",
    icon: `<svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.1666 11.6667V16.6667M11.6666 14.1667H16.6666M4.99998 8.33333H6.66665C7.58712 8.33333 8.33331 7.58714 8.33331 6.66666V4.99999C8.33331 4.07952 7.58712 3.33333 6.66665 3.33333H4.99998C4.07951 3.33333 3.33331 4.07952 3.33331 4.99999V6.66666C3.33331 7.58714 4.07951 8.33333 4.99998 8.33333ZM13.3333 8.33333H15C15.9205 8.33333 16.6666 7.58714 16.6666 6.66666V4.99999C16.6666 4.07952 15.9205 3.33333 15 3.33333H13.3333C12.4128 3.33333 11.6666 4.07952 11.6666 4.99999V6.66666C11.6666 7.58714 12.4128 8.33333 13.3333 8.33333ZM4.99998 16.6667H6.66665C7.58712 16.6667 8.33331 15.9205 8.33331 15V13.3333C8.33331 12.4129 7.58712 11.6667 6.66665 11.6667H4.99998C4.07951 11.6667 3.33331 12.4129 3.33331 13.3333V15C3.33331 15.9205 4.07951 16.6667 4.99998 16.6667Z"
          stroke="#404040"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>`,
    iconActive: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.1666 11.6667V16.6667M11.6666 14.1667H16.6666M4.99998 8.33333H6.66665C7.58712 8.33333 8.33331 7.58714 8.33331 6.66666V4.99999C8.33331 4.07952 7.58712 3.33333 6.66665 3.33333H4.99998C4.07951 3.33333 3.33331 4.07952 3.33331 4.99999V6.66666C3.33331 7.58714 4.07951 8.33333 4.99998 8.33333ZM13.3333 8.33333H15C15.9205 8.33333 16.6666 7.58714 16.6666 6.66666V4.99999C16.6666 4.07952 15.9205 3.33333 15 3.33333H13.3333C12.4128 3.33333 11.6666 4.07952 11.6666 4.99999V6.66666C11.6666 7.58714 12.4128 8.33333 13.3333 8.33333ZM4.99998 16.6667H6.66665C7.58712 16.6667 8.33331 15.9205 8.33331 15V13.3333C8.33331 12.4129 7.58712 11.6667 6.66665 11.6667H4.99998C4.07951 11.6667 3.33331 12.4129 3.33331 13.3333V15C3.33331 15.9205 4.07951 16.6667 4.99998 16.6667Z" stroke="#404040" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`,
  },
  {
    name: "Payment Reminders",
    path: "/payment-reminders",
    icon: `<svg width="20" height="20" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.45999 8.87063H12.7062V10.355H6.45999V8.87063ZM6.45999 11.6731H12.7062V13.1575H6.45999V11.6731ZM6.45999 6.02063H12.7062V7.50501H6.45999V6.02063Z" fill="#404040"/>
<path d="M13.4663 1.1875L11.4475 0.3325L9.59501 1.1875L7.61189 0.3325L5.74751 1.1875L2.92126 0V19L5.74751 17.8125L7.61189 18.6319L9.59501 17.8125L11.4475 18.6319L13.4663 17.8125L16.0788 19V0L13.4663 1.1875ZM14.5825 16.7556L13.49 16.2687L11.4831 17.0881L9.61876 16.2331L7.62376 17.0525L5.78314 16.2331L4.41751 16.7913V2.20875L5.78314 2.76687L7.62376 1.9475L9.61876 2.76687L11.4831 1.9475L13.49 2.76687L14.5825 2.28V16.7556Z" fill="#404040"/>
</svg>`,
    iconActive: `<svg width="20" height="20" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.45999 8.87063H12.7062V10.355H6.45999V8.87063ZM6.45999 11.6731H12.7062V13.1575H6.45999V11.6731ZM6.45999 6.02063H12.7062V7.50501H6.45999V6.02063Z" fill="#404040"/>
<path d="M13.4663 1.1875L11.4475 0.3325L9.59501 1.1875L7.61189 0.3325L5.74751 1.1875L2.92126 0V19L5.74751 17.8125L7.61189 18.6319L9.59501 17.8125L11.4475 18.6319L13.4663 17.8125L16.0788 19V0L13.4663 1.1875ZM14.5825 16.7556L13.49 16.2687L11.4831 17.0881L9.61876 16.2331L7.62376 17.0525L5.78314 16.2331L4.41751 16.7913V2.20875L5.78314 2.76687L7.62376 1.9475L9.61876 2.76687L11.4831 1.9475L13.49 2.76687L14.5825 2.28V16.7556Z" fill="#404040"/>
</svg>`,
  },
  {
    name: "Client List",
    path: "/client-list",
    icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.80221 2.94871C6.33551 2.94871 5.14652 4.13771 5.14652 5.6044C5.14652 7.07108 6.33551 8.26007 7.80221 8.26007C9.26892 8.26007 10.4578 7.07108 10.4578 5.6044C10.4578 4.13771 9.26892 2.94871 7.80221 2.94871ZM3.86447 5.6044C3.86447 3.42965 5.62746 1.66666 7.80221 1.66666C9.97692 1.66666 11.7399 3.42965 11.7399 5.6044C11.7399 7.77914 9.97692 9.54216 7.80221 9.54216C5.62746 9.54216 3.86447 7.77914 3.86447 5.6044Z" fill="#404040"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.17626 12.8388C4.39373 12.8388 2.94871 14.2838 2.94871 16.0664C2.94871 16.1551 2.96401 16.2163 2.97937 16.2511C2.99175 16.279 3.00087 16.2843 3.00491 16.2867C3.4446 16.5451 4.70943 17.0512 7.80219 17.0512C10.8949 17.0512 12.1594 16.5452 12.5991 16.2869C12.6032 16.2845 12.6127 16.279 12.625 16.2511C12.6404 16.2163 12.6557 16.1551 12.6557 16.0664C12.6557 14.2838 11.2107 12.8388 9.42807 12.8388H6.17626ZM1.66666 16.0664C1.66666 13.5758 3.68567 11.5567 6.17626 11.5567H9.42807C11.9187 11.5567 13.9377 13.5758 13.9377 16.0664C13.9377 16.4953 13.7927 17.0724 13.2487 17.3922C12.5317 17.8136 11.0074 18.3333 7.80219 18.3333C4.59698 18.3333 3.07267 17.8136 2.35568 17.3922C1.81164 17.0724 1.66666 16.4953 1.66666 16.0664Z" fill="#404040"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.9526 3.23934C13.045 2.89757 13.3969 2.6954 13.7387 2.78777C15.1527 3.16993 16.1355 4.56624 16.1355 6.15384C16.1355 7.83136 14.9858 9.34383 13.3732 9.53758C13.0217 9.57975 12.7025 9.32908 12.6603 8.97758C12.618 8.62608 12.8688 8.30686 13.2203 8.26465C14.0802 8.16135 14.8535 7.29784 14.8535 6.15384C14.8535 5.06844 14.188 4.23725 13.4042 4.02542C13.0624 3.93305 12.8603 3.58111 12.9526 3.23934Z" fill="#404040"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.8653 12.0751C14.9331 11.7276 15.2697 11.5008 15.6172 11.5687C17.1947 11.8763 18.3333 13.2583 18.3333 14.8657V15.3113C18.3333 15.7058 18.215 16.2677 17.7003 16.5966C17.3538 16.8181 16.8165 17.0541 15.9879 17.2217C15.6409 17.2919 15.3027 17.0675 15.2325 16.7205C15.1623 16.3735 15.3867 16.0353 15.7337 15.9651C16.4406 15.8221 16.8226 15.6361 17.0098 15.5164L17.0113 15.5149C17.0127 15.5132 17.0177 15.5064 17.0239 15.4913C17.0376 15.4582 17.0512 15.3987 17.0512 15.3113V14.8657C17.0512 13.8718 16.3472 13.0172 15.3718 12.827C15.0243 12.7592 14.7976 12.4226 14.8653 12.0751Z" fill="#404040"/>
</svg>`,
    iconActive: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.80221 2.94871C6.33551 2.94871 5.14652 4.13771 5.14652 5.6044C5.14652 7.07108 6.33551 8.26007 7.80221 8.26007C9.26892 8.26007 10.4578 7.07108 10.4578 5.6044C10.4578 4.13771 9.26892 2.94871 7.80221 2.94871ZM3.86447 5.6044C3.86447 3.42965 5.62746 1.66666 7.80221 1.66666C9.97692 1.66666 11.7399 3.42965 11.7399 5.6044C11.7399 7.77914 9.97692 9.54216 7.80221 9.54216C5.62746 9.54216 3.86447 7.77914 3.86447 5.6044Z" fill="#404040"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.17626 12.8388C4.39373 12.8388 2.94871 14.2838 2.94871 16.0664C2.94871 16.1551 2.96401 16.2163 2.97937 16.2511C2.99175 16.279 3.00087 16.2843 3.00491 16.2867C3.4446 16.5451 4.70943 17.0512 7.80219 17.0512C10.8949 17.0512 12.1594 16.5452 12.5991 16.2869C12.6032 16.2845 12.6127 16.279 12.625 16.2511C12.6404 16.2163 12.6557 16.1551 12.6557 16.0664C12.6557 14.2838 11.2107 12.8388 9.42807 12.8388H6.17626ZM1.66666 16.0664C1.66666 13.5758 3.68567 11.5567 6.17626 11.5567H9.42807C11.9187 11.5567 13.9377 13.5758 13.9377 16.0664C13.9377 16.4953 13.7927 17.0724 13.2487 17.3922C12.5317 17.8136 11.0074 18.3333 7.80219 18.3333C4.59698 18.3333 3.07267 17.8136 2.35568 17.3922C1.81164 17.0724 1.66666 16.4953 1.66666 16.0664Z" fill="#404040"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.9526 3.23934C13.045 2.89757 13.3969 2.6954 13.7387 2.78777C15.1527 3.16993 16.1355 4.56624 16.1355 6.15384C16.1355 7.83136 14.9858 9.34383 13.3732 9.53758C13.0217 9.57975 12.7025 9.32908 12.6603 8.97758C12.618 8.62608 12.8688 8.30686 13.2203 8.26465C14.0802 8.16135 14.8535 7.29784 14.8535 6.15384C14.8535 5.06844 14.188 4.23725 13.4042 4.02542C13.0624 3.93305 12.8603 3.58111 12.9526 3.23934Z" fill="#404040"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.8653 12.0751C14.9331 11.7276 15.2697 11.5008 15.6172 11.5687C17.1947 11.8763 18.3333 13.2583 18.3333 14.8657V15.3113C18.3333 15.7058 18.215 16.2677 17.7003 16.5966C17.3538 16.8181 16.8165 17.0541 15.9879 17.2217C15.6409 17.2919 15.3027 17.0675 15.2325 16.7205C15.1623 16.3735 15.3867 16.0353 15.7337 15.9651C16.4406 15.8221 16.8226 15.6361 17.0098 15.5164L17.0113 15.5149C17.0127 15.5132 17.0177 15.5064 17.0239 15.4913C17.0376 15.4582 17.0512 15.3987 17.0512 15.3113V14.8657C17.0512 13.8718 16.3472 13.0172 15.3718 12.827C15.0243 12.7592 14.7976 12.4226 14.8653 12.0751Z" fill="#404040"/>
</svg>`,
  },
  {
    name: "Staff Details",
    path: "/staff-details",
    icon: `<svg width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.0625 4.66083C12.0734 4.66083 12.8929 3.84133 12.8929 2.83042C12.8929 1.81951 12.0734 1 11.0625 1C10.0516 1 9.23212 1.81951 9.23212 2.83042C9.23212 3.84133 10.0516 4.66083 11.0625 4.66083Z" stroke="#404040" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M8.32169 7.40166C8.32169 6.67475 8.61045 5.97761 9.12446 5.4636C9.63846 4.94959 10.3356 4.66083 11.0625 4.66083C11.7894 4.66083 12.4866 4.94959 13.0006 5.4636C13.5146 5.97761 13.8034 6.67475 13.8034 7.40166" stroke="#404040" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M18.3842 15.6337C19.3951 15.6337 20.2146 14.8142 20.2146 13.8033C20.2146 12.7924 19.3951 11.9729 18.3842 11.9729C17.3733 11.9729 16.5538 12.7924 16.5538 13.8033C16.5538 14.8142 17.3733 15.6337 18.3842 15.6337Z" stroke="#404040" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M15.6337 18.3842C15.6325 18.0226 15.7028 17.6644 15.8405 17.3301C15.9783 16.9959 16.1808 16.6922 16.4365 16.4365C16.6922 16.1808 16.9959 15.9783 17.3301 15.8405C17.6644 15.7028 18.0226 15.6325 18.3842 15.6337C18.7449 15.6337 19.1021 15.705 19.4353 15.8433C19.7684 15.9816 20.071 16.1844 20.3256 16.4399C20.5803 16.6954 20.7819 16.9987 20.9191 17.3323C21.0563 17.666 21.1263 18.0234 21.125 18.3842" stroke="#404040" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M3.74085 15.6337C4.75176 15.6337 5.57126 14.8142 5.57126 13.8033C5.57126 12.7924 4.75176 11.9729 3.74085 11.9729C2.72994 11.9729 1.91043 12.7924 1.91043 13.8033C1.91043 14.8142 2.72994 15.6337 3.74085 15.6337Z" stroke="#404040" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M1.00002 18.3842C0.998755 18.0234 1.06872 17.666 1.20589 17.3323C1.34307 16.9987 1.54476 16.6954 1.79939 16.4399C2.05403 16.1844 2.3566 15.9816 2.68976 15.8433C3.02292 15.705 3.38011 15.6337 3.74085 15.6337C4.10239 15.6325 4.46062 15.7028 4.79489 15.8405C5.12915 15.9783 5.43286 16.1808 5.68851 16.4365C5.94416 16.6922 6.14671 16.9959 6.28448 17.3301C6.42226 17.6644 6.49253 18.0226 6.49127 18.3842" stroke="#404040" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M1.9296 10.4588C2.03035 8.92986 2.51414 7.45088 3.33642 6.158C4.15871 4.86512 5.29305 3.7999 6.63501 3.06042" stroke="#404040" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M15.49 3.06042C16.832 3.7999 17.9663 4.86512 18.7886 6.158C19.6109 7.45088 20.0947 8.92986 20.1954 10.4588" stroke="#404040" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M7.60294 20.4446C9.82006 21.3521 12.305 21.3521 14.5221 20.4446" stroke="#404040" stroke-width="1.5" stroke-miterlimit="10"/>
</svg>`,
    iconActive: `<svg width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.0625 4.66083C12.0734 4.66083 12.8929 3.84133 12.8929 2.83042C12.8929 1.81951 12.0734 1 11.0625 1C10.0516 1 9.23212 1.81951 9.23212 2.83042C9.23212 3.84133 10.0516 4.66083 11.0625 4.66083Z" stroke="#404040" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M8.32169 7.40166C8.32169 6.67475 8.61045 5.97761 9.12446 5.4636C9.63846 4.94959 10.3356 4.66083 11.0625 4.66083C11.7894 4.66083 12.4866 4.94959 13.0006 5.4636C13.5146 5.97761 13.8034 6.67475 13.8034 7.40166" stroke="#404040" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M18.3842 15.6337C19.3951 15.6337 20.2146 14.8142 20.2146 13.8033C20.2146 12.7924 19.3951 11.9729 18.3842 11.9729C17.3733 11.9729 16.5538 12.7924 16.5538 13.8033C16.5538 14.8142 17.3733 15.6337 18.3842 15.6337Z" stroke="#404040" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M15.6337 18.3842C15.6325 18.0226 15.7028 17.6644 15.8405 17.3301C15.9783 16.9959 16.1808 16.6922 16.4365 16.4365C16.6922 16.1808 16.9959 15.9783 17.3301 15.8405C17.6644 15.7028 18.0226 15.6325 18.3842 15.6337C18.7449 15.6337 19.1021 15.705 19.4353 15.8433C19.7684 15.9816 20.071 16.1844 20.3256 16.4399C20.5803 16.6954 20.7819 16.9987 20.9191 17.3323C21.0563 17.666 21.1263 18.0234 21.125 18.3842" stroke="#404040" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M3.74085 15.6337C4.75176 15.6337 5.57126 14.8142 5.57126 13.8033C5.57126 12.7924 4.75176 11.9729 3.74085 11.9729C2.72994 11.9729 1.91043 12.7924 1.91043 13.8033C1.91043 14.8142 2.72994 15.6337 3.74085 15.6337Z" stroke="#404040" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M1.00002 18.3842C0.998755 18.0234 1.06872 17.666 1.20589 17.3323C1.34307 16.9987 1.54476 16.6954 1.79939 16.4399C2.05403 16.1844 2.3566 15.9816 2.68976 15.8433C3.02292 15.705 3.38011 15.6337 3.74085 15.6337C4.10239 15.6325 4.46062 15.7028 4.79489 15.8405C5.12915 15.9783 5.43286 16.1808 5.68851 16.4365C5.94416 16.6922 6.14671 16.9959 6.28448 17.3301C6.42226 17.6644 6.49253 18.0226 6.49127 18.3842" stroke="#404040" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M1.9296 10.4588C2.03035 8.92986 2.51414 7.45088 3.33642 6.158C4.15871 4.86512 5.29305 3.7999 6.63501 3.06042" stroke="#404040" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M15.49 3.06042C16.832 3.7999 17.9663 4.86512 18.7886 6.158C19.6109 7.45088 20.0947 8.92986 20.1954 10.4588" stroke="#404040" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M7.60294 20.4446C9.82006 21.3521 12.305 21.3521 14.5221 20.4446" stroke="#404040" stroke-width="1.5" stroke-miterlimit="10"/>
</svg>`,
  },
 
];

export const sidebarSettingsRoutes = [
  {
    name: "Reminder Configuration",
    path: "/reminder-configuration",
    icon: `<svg width="20" height="20" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.12605 11.3278V10.5058C6.12605 5.99227 9.65136 2.33334 14 2.33334C18.3487 2.33334 21.874 5.99227 21.874 10.5058V11.3278C21.874 12.3144 22.1553 13.2788 22.6826 14.0996L23.9746 16.1111C25.1547 17.9484 24.2538 20.4457 22.2012 21.0267C16.8318 22.5467 11.1681 22.5467 5.7988 21.0267C3.74617 20.4457 2.84526 17.9484 4.02546 16.1111L5.31743 14.0996C5.84465 13.2788 6.12605 12.3144 6.12605 11.3278Z" stroke="#404040" stroke-width="2"/>
<path d="M19.25 22.1667C18.4858 24.2058 16.4238 25.6667 14 25.6667C11.5763 25.6667 9.51417 24.2058 8.75 22.1667" stroke="#404040" stroke-width="2" stroke-linecap="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.4193 16.6625C14.809 16.6625 15.1438 16.3942 15.2171 16.0231L15.2497 15.8581C15.3778 15.2096 16.0697 14.8223 16.7131 15.039L16.8769 15.0941C17.245 15.2181 17.652 15.0712 17.8468 14.744L18.1411 14.2498C18.336 13.9226 18.2637 13.5074 17.9689 13.2603L17.8378 13.1504C17.3225 12.7185 17.3225 11.9439 17.8378 11.5121L17.9689 11.4022C18.2637 11.1551 18.336 10.7399 18.1411 10.4127L17.8469 9.91854C17.652 9.59135 17.245 9.44443 16.8768 9.5684L16.7131 9.62356C16.0697 9.84027 15.3778 9.45297 15.2497 8.80437L15.2171 8.63937C15.1438 8.26831 14.809 8 14.4193 8H13.8307C13.441 8 13.1062 8.26831 13.0329 8.63934L13.0003 8.80445C12.8722 9.45297 12.1802 9.84027 11.5368 9.62356L11.3732 9.56844C11.0051 9.44442 10.598 9.59139 10.4031 9.91857L10.1089 10.4127C9.91402 10.7399 9.98629 11.1551 10.2811 11.4022L10.4122 11.5121C10.9275 11.944 10.9275 12.7185 10.4122 13.1504L10.2811 13.2603C9.98629 13.5074 9.91402 13.9226 10.1089 14.2498L10.4032 14.744C10.598 15.0712 11.0051 15.2181 11.3732 15.0941L11.5369 15.039C12.1803 14.8223 12.8722 15.2096 13.0003 15.8581L13.0329 16.0232C13.1062 16.3942 13.441 16.6625 13.8307 16.6625H14.4193ZM14.125 11.0937C14.8084 11.0937 15.3625 11.6478 15.3625 12.3312C15.3625 13.0147 14.8084 13.5688 14.125 13.5688C13.4415 13.5688 12.8875 13.0147 12.8875 12.3312C12.8875 11.6478 13.4415 11.0937 14.125 11.0937Z" fill="#404040"/>
</svg>`,
    iconActive: `<svg width="20" height="20" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.12605 11.3278V10.5058C6.12605 5.99227 9.65136 2.33334 14 2.33334C18.3487 2.33334 21.874 5.99227 21.874 10.5058V11.3278C21.874 12.3144 22.1553 13.2788 22.6826 14.0996L23.9746 16.1111C25.1547 17.9484 24.2538 20.4457 22.2012 21.0267C16.8318 22.5467 11.1681 22.5467 5.7988 21.0267C3.74617 20.4457 2.84526 17.9484 4.02546 16.1111L5.31743 14.0996C5.84465 13.2788 6.12605 12.3144 6.12605 11.3278Z" stroke="#404040" stroke-width="2"/>
<path d="M19.25 22.1667C18.4858 24.2058 16.4238 25.6667 14 25.6667C11.5763 25.6667 9.51417 24.2058 8.75 22.1667" stroke="#404040" stroke-width="2" stroke-linecap="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.4193 16.6625C14.809 16.6625 15.1438 16.3942 15.2171 16.0231L15.2497 15.8581C15.3778 15.2096 16.0697 14.8223 16.7131 15.039L16.8769 15.0941C17.245 15.2181 17.652 15.0712 17.8468 14.744L18.1411 14.2498C18.336 13.9226 18.2637 13.5074 17.9689 13.2603L17.8378 13.1504C17.3225 12.7185 17.3225 11.9439 17.8378 11.5121L17.9689 11.4022C18.2637 11.1551 18.336 10.7399 18.1411 10.4127L17.8469 9.91854C17.652 9.59135 17.245 9.44443 16.8768 9.5684L16.7131 9.62356C16.0697 9.84027 15.3778 9.45297 15.2497 8.80437L15.2171 8.63937C15.1438 8.26831 14.809 8 14.4193 8H13.8307C13.441 8 13.1062 8.26831 13.0329 8.63934L13.0003 8.80445C12.8722 9.45297 12.1802 9.84027 11.5368 9.62356L11.3732 9.56844C11.0051 9.44442 10.598 9.59139 10.4031 9.91857L10.1089 10.4127C9.91402 10.7399 9.98629 11.1551 10.2811 11.4022L10.4122 11.5121C10.9275 11.944 10.9275 12.7185 10.4122 13.1504L10.2811 13.2603C9.98629 13.5074 9.91402 13.9226 10.1089 14.2498L10.4032 14.744C10.598 15.0712 11.0051 15.2181 11.3732 15.0941L11.5369 15.039C12.1803 14.8223 12.8722 15.2096 13.0003 15.8581L13.0329 16.0232C13.1062 16.3942 13.441 16.6625 13.8307 16.6625H14.4193ZM14.125 11.0937C14.8084 11.0937 15.3625 11.6478 15.3625 12.3312C15.3625 13.0147 14.8084 13.5688 14.125 13.5688C13.4415 13.5688 12.8875 13.0147 12.8875 12.3312C12.8875 11.6478 13.4415 11.0937 14.125 11.0937Z" fill="#404040"/>
</svg>`,
  },
  {
    name: "Settings",
    path: "/settings",
    icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.60386 3.59776C8.95919 2.13408 11.0408 2.13408 11.3961 3.59776C11.6257 4.54327 12.709 4.99198 13.5398 4.48571C14.8261 3.70199 16.298 5.17392 15.5143 6.46015C15.008 7.29105 15.4567 8.37431 16.4022 8.60386C17.8659 8.95919 17.8659 11.0408 16.4022 11.3961C15.4567 11.6257 15.008 12.709 15.5143 13.5398C16.298 14.8261 14.8261 16.298 13.5398 15.5143C12.709 15.008 11.6257 15.4567 11.3961 16.4022C11.0408 17.8659 8.95919 17.8659 8.60386 16.4022C8.37431 15.4567 7.29105 15.008 6.46016 15.5143C5.17392 16.298 3.70199 14.8261 4.48571 13.5398C4.99198 12.709 4.54327 11.6257 3.59776 11.3961C2.13408 11.0408 2.13408 8.95919 3.59776 8.60386C4.54327 8.37431 4.99198 7.29105 4.48571 6.46015C3.70199 5.17392 5.17392 3.70199 6.46015 4.48571C7.29105 4.99198 8.37431 4.54327 8.60386 3.59776Z" stroke="#404040" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.5 10C12.5 11.3807 11.3807 12.5 10 12.5C8.61929 12.5 7.5 11.3807 7.5 10C7.5 8.61929 8.61929 7.5 10 7.5C11.3807 7.5 12.5 8.61929 12.5 10Z" stroke="#404040" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
    iconActive: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.60386 3.59776C8.95919 2.13408 11.0408 2.13408 11.3961 3.59776C11.6257 4.54327 12.709 4.99198 13.5398 4.48571C14.8261 3.70199 16.298 5.17392 15.5143 6.46015C15.008 7.29105 15.4567 8.37431 16.4022 8.60386C17.8659 8.95919 17.8659 11.0408 16.4022 11.3961C15.4567 11.6257 15.008 12.709 15.5143 13.5398C16.298 14.8261 14.8261 16.298 13.5398 15.5143C12.709 15.008 11.6257 15.4567 11.3961 16.4022C11.0408 17.8659 8.95919 17.8659 8.60386 16.4022C8.37431 15.4567 7.29105 15.008 6.46016 15.5143C5.17392 16.298 3.70199 14.8261 4.48571 13.5398C4.99198 12.709 4.54327 11.6257 3.59776 11.3961C2.13408 11.0408 2.13408 8.95919 3.59776 8.60386C4.54327 8.37431 4.99198 7.29105 4.48571 6.46015C3.70199 5.17392 5.17392 3.70199 6.46015 4.48571C7.29105 4.99198 8.37431 4.54327 8.60386 3.59776Z" stroke="#404040" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.5 10C12.5 11.3807 11.3807 12.5 10 12.5C8.61929 12.5 7.5 11.3807 7.5 10C7.5 8.61929 8.61929 7.5 10 7.5C11.3807 7.5 12.5 8.61929 12.5 10Z" stroke="#404040" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
  },
  {
    name: "Help",
    path: "/help",
    icon: `<svg width="20" height="20" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.6251 8.60416C18.3021 5.29166 16.3438 0.0833282 10.2917 0.0833282C4.23964 0.0833282 2.28131 5.29166 1.95839 8.60416C0.825593 9.03409 0.0783804 10.1217 0.0833683 11.3333V12.7917C0.0833683 14.4025 1.38923 15.7083 3.00006 15.7083C4.61089 15.7083 5.91673 14.4025 5.91673 12.7917V11.3333C5.91149 10.1479 5.19172 9.08259 4.09381 8.63541C4.30214 6.71875 5.32298 2.16666 10.2917 2.16666C15.2605 2.16666 16.2709 6.71875 16.4792 8.63541C15.3835 9.08356 14.6674 10.1495 14.6667 11.3333V12.7917C14.6713 13.9114 15.3142 14.9305 16.323 15.4167C15.8855 16.2396 14.7709 17.3542 12.0313 17.6875C11.4837 16.8559 10.4242 16.5281 9.50261 16.9051C8.58104 17.2821 8.05504 18.2585 8.24723 19.2354C8.43941 20.2124 9.29603 20.9167 10.2917 20.9167C11.0785 20.9123 11.7958 20.465 12.1459 19.7604C16.6146 19.25 18.0417 16.9479 18.4896 15.5937C19.7014 15.2012 20.5164 14.0653 20.5001 12.7917V11.3333C20.5051 10.1217 19.7579 9.03409 18.6251 8.60416ZM3.83339 12.7917C3.83339 13.2519 3.4603 13.625 3.00006 13.625C2.53982 13.625 2.16673 13.2519 2.16673 12.7917V11.3333C2.16673 11.0356 2.32556 10.7605 2.58339 10.6116C2.84123 10.4628 3.15889 10.4628 3.41673 10.6116C3.67456 10.7605 3.83339 11.0356 3.83339 11.3333V12.7917ZM16.7501 11.3333C16.7501 10.8731 17.1232 10.5 17.5834 10.5C18.0436 10.5 18.4167 10.8731 18.4167 11.3333V12.7917C18.4167 13.2519 18.0436 13.625 17.5834 13.625C17.1232 13.625 16.7501 13.2519 16.7501 12.7917V11.3333Z" fill="#404040"/>
</svg>`,
    iconActive: `<svg width="20" height="20" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.6251 8.60416C18.3021 5.29166 16.3438 0.0833282 10.2917 0.0833282C4.23964 0.0833282 2.28131 5.29166 1.95839 8.60416C0.825593 9.03409 0.0783804 10.1217 0.0833683 11.3333V12.7917C0.0833683 14.4025 1.38923 15.7083 3.00006 15.7083C4.61089 15.7083 5.91673 14.4025 5.91673 12.7917V11.3333C5.91149 10.1479 5.19172 9.08259 4.09381 8.63541C4.30214 6.71875 5.32298 2.16666 10.2917 2.16666C15.2605 2.16666 16.2709 6.71875 16.4792 8.63541C15.3835 9.08356 14.6674 10.1495 14.6667 11.3333V12.7917C14.6713 13.9114 15.3142 14.9305 16.323 15.4167C15.8855 16.2396 14.7709 17.3542 12.0313 17.6875C11.4837 16.8559 10.4242 16.5281 9.50261 16.9051C8.58104 17.2821 8.05504 18.2585 8.24723 19.2354C8.43941 20.2124 9.29603 20.9167 10.2917 20.9167C11.0785 20.9123 11.7958 20.465 12.1459 19.7604C16.6146 19.25 18.0417 16.9479 18.4896 15.5937C19.7014 15.2012 20.5164 14.0653 20.5001 12.7917V11.3333C20.5051 10.1217 19.7579 9.03409 18.6251 8.60416ZM3.83339 12.7917C3.83339 13.2519 3.4603 13.625 3.00006 13.625C2.53982 13.625 2.16673 13.2519 2.16673 12.7917V11.3333C2.16673 11.0356 2.32556 10.7605 2.58339 10.6116C2.84123 10.4628 3.15889 10.4628 3.41673 10.6116C3.67456 10.7605 3.83339 11.0356 3.83339 11.3333V12.7917ZM16.7501 11.3333C16.7501 10.8731 17.1232 10.5 17.5834 10.5C18.0436 10.5 18.4167 10.8731 18.4167 11.3333V12.7917C18.4167 13.2519 18.0436 13.625 17.5834 13.625C17.1232 13.625 16.7501 13.2519 16.7501 12.7917V11.3333Z" fill="#404040"/>
</svg>`,
  },
];
