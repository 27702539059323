import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { UserContext } from "@/context/user-context";
import moment from "moment";
import { useContext } from "react";
import { Link } from "react-router-dom";
import CurrencyFormat from "react-currency-format";

const PlanTab = () => {
  const { currentUser } = useContext(UserContext);

  return (
    <div className="mx-auto rounded-md bg-white p-6 shadow-md sm:w-1/2">
      <p className="mb-6 text-lg font-semibold">Plan Details</p>
      {currentUser?.subscription?.status === "active" ? (
        <div className="space-y-6">
          <div className="flex flex-col gap-1">
            <label>Plan Code</label>
            <Input
              disabled
              placeholder="Plan Code"
              value={currentUser?.subscription?.planCode}
            />
          </div>
          <div className="flex flex-col gap-1">
            <label>Amount</label>
            {/* <Input
              disabled
              placeholder="Plan Activation Date" */}
            {/* value={ */}

            <CurrencyFormat
              displayType={"text"}
              thousandSeparator={true}
              value={currentUser?.subscription?.amount}
              thousandSpacing="2s"
              prefix={"₹"}
              renderText={(value) => (
                <p className="flex h-10 items-center rounded-lg border px-3 text-sm text-gray-500">
                  {value}
                </p>
              )}
            />
            {/* } */}
            {/* /> */}
          </div>
          <div className="flex flex-col gap-1">
            <label>Activation Date</label>
            <Input
              disabled
              placeholder="Plan Activation Date"
              value={moment(currentUser?.subscription?.activationDate).format(
                "DD-MMM-YYYY",
              )}
            />
          </div>
          <div className="flex flex-col gap-1">
            <label>Expiration Date</label>
            <Input
              disabled
              placeholder="Plan Activation Date"
              value={moment(currentUser?.subscription?.expiryDate).format(
                "DD-MMM-YYYY",
              )}
            />
          </div>
        </div>
      ) : (
        <p className="text-sm text-gray-500">
          You are not subscribed to any plan. Please subscribe to a plan to
          continue using the application.
        </p>
      )}
      <Button className="mt-6">
        <Link
          to={"https://billing.finbite.in/checkout/66b855e39a70b5449741de12"}
        >
          {currentUser?.subscription?.status === "active"
            ? "Update"
            : "Purchase"}{" "}
          Plan
        </Link>
      </Button>
    </div>
  );
};

export default PlanTab;
