import { addDataToSheet, getSheetClientIDs } from "@/api/user";
import InvoiceForm from "@/components/forms/invoice-form";
import { EmailReminderContext } from "@/context/email-reminder-context";
import { useMutation, useQuery } from "@tanstack/react-query";
import { RotateCw } from "lucide-react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

const AddInvoice = () => {
  const navigate = useNavigate();

  const { reminderConfiguration } = useContext(EmailReminderContext);

  const mutation = useMutation({
    mutationFn: addDataToSheet,
    onSuccess: () => {
      navigate("/payment-reminders");
      toast.success("Payment reminder added successfully");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const {
    data: clientIDs,
    isFetching,
  }: {
    data: any;
    isFetching: boolean;
  } = useQuery({
    queryKey: ["sheetData"],
    queryFn: async () => getSheetClientIDs(),
  });

  const onSubmit = (values: any) => {
    // send array of strings to the mutation
    mutation.mutate({
      data: {
        ...values,
        reminderID: reminderConfiguration?._id,
      },
      sheetName: "Payment Reminders",
    });
  };

  if (isFetching) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <RotateCw size={16} className="mx-auto animate-spin" />
      </div>
    );
  }

  return (
    <InvoiceForm
      onSubmit={onSubmit}
      isPending={mutation.isPending}
      clientIDs={clientIDs}
    />
  );
};

export default AddInvoice;
