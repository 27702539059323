import { SigninData, SignupData } from "@/lib/constants/interface";
import axiosClient from "./axios-client";

export const register = async (data: SignupData) => {
  return axiosClient.post("/auth/register", data);
};

export const login = async (data: SigninData) => {
  return axiosClient.post("/auth/login", data);
};

export const logout = async () => {
  return axiosClient.post("/auth/logout");
};

export const forgotPassword = async (email: string) => {
  return axiosClient.post("/auth/forgot-password", { email });
};

export const resetPassword = async (data: {
  password: string;
  token: string;
  userId: string;
}) => {
  return axiosClient.post("/auth/reset-password", data);
};

export const sendVerificationLink = async (email: string) => {
  return axiosClient.post("/auth/send-verification-link", { email });
};

export const verifyEmail = async (data: { token: string; userId: string }) => {
  return axiosClient.post("/auth/verify-email", data);
};
