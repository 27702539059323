import { getRevenueByMonth } from "@/api/user";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import CurrencyFormat from "react-currency-format";

const RevenueByMonth = () => {
  const monthOptions = [
    {
      label: "Current Month",
      value: "currentMonth",
    },
    {
      label: "Current Year",
      value: "currentYear",
    },
  ];

  const [month, setMonth] = useState(monthOptions[0].value);

  const { data }: { data: any; isLoading: boolean } = useQuery({
    queryKey: ["outstandingByDate", month],
    queryFn: () => getRevenueByMonth(month),
    enabled: !!month,
  });
  console.log(data);
  return (
    <div className="relative flex h-[21.25rem] flex-col items-center justify-center gap-5 rounded-xl border p-5 text-center">
      <div className="absolute top-5 flex flex-col gap-5">
        <p>Total Revenue</p>
        <div className="w-[150px]">
          <Select
            defaultValue={monthOptions[0].value}
            onValueChange={(e) => setMonth(e)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select Revenue Month" />
            </SelectTrigger>
            <SelectContent>
              {monthOptions.map((month) => (
                <SelectItem key={month.value} value={month.value}>
                  {month.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
      <CurrencyFormat
        displayType={"text"}
        thousandSeparator={true}
        value={Number(data?.totalRevenue).toFixed(0)}
        thousandSpacing="2s"
        prefix={"₹"}
        renderText={(value) => (
          <p className="text-3xl font-semibold">{value}</p>
        )}
      />
    </div>
  );
};

export default RevenueByMonth;
