import { CustomMail } from "@/utils/constants";
import axiosClient from "./axios-client";

type ExtendedCustomMail = CustomMail & {
  to: string;
};

export const getUser = async () => {
  return axiosClient.get("/api/user");
};
export const disconnectGmail = async () => {
  return axiosClient.put("/api/user/gmail/disconnect");
};
export const disconnectSheet = async () => {
  return axiosClient.put("/api/user/sheets/disconnect");
};
export const connectCustomMail = async (data: CustomMail) => {
  return axiosClient.put("/api/user/custom-mail/connect", data);
};
export const sendTestMail = async (data: ExtendedCustomMail) => {
  return axiosClient.post("/api/user/custom-mail/test", data);
};
export const disconnectCustomMail = async () => {
  return axiosClient.put("/api/user/custom-mail/disconnect");
};
export const getSheets = async () => {
  return axiosClient.get("/api/user/sheets");
};
export const getSheetClientIDs = async () => {
  return axiosClient.get(`/api/user/sheets/client-ids`);
};
export const addDataToSheet = async ({
  data,
  sheetName,
}: {
  data: any;
  sheetName: string;
}) => {
  const formData = new FormData();

  for (const key in data) {
    if (key === "attachment") {
      formData.append("file", data[key]);
    } else {
      formData.append(key, data[key]);
    }
  }

  axiosClient.defaults.headers["Content-Type"] = "multipart/form-data";

  const res = await axiosClient.post(
    `/api/user/sheets/payment-reminder/add/${sheetName}`,
    data,
  );

  axiosClient.defaults.headers["Content-Type"] = "application/json";

  return res;
};
export const getSheetData = async (sheetName: string) => {
  return axiosClient.get(`/api/user/sheets/single/${sheetName}`);
};
export const getSingleSheetRow = async ({
  sheetName,
  rowId,
}: {
  sheetName: string;
  rowId: string;
}) => {
  return axiosClient.get(`/api/user/sheets/single/${sheetName}/${rowId}`);
};
export const updateSheetRow = async ({
  data,
  sheetName,
  rowId,
}: {
  data: any;
  sheetName: string;
  rowId: string;
}) => {
  const formData = new FormData();

  for (const key in data) {
    if (key === "attachment" && typeof data[key] !== "string") {
      formData.append("file", data[key]);
    } else {
      formData.append(key, data[key]);
    }
  }

  axiosClient.defaults.headers["Content-Type"] = "multipart/form-data";

  const res = await axiosClient.post(
    `/api/user/sheets/update/${sheetName}/${rowId}`,
    data,
  );

  axiosClient.defaults.headers["Content-Type"] = "application/json";

  return res;
};
export const deleteSheetRows = async ({
  sheetName,
  rows,
  invoiceID,
}: {
  sheetName: string;
  rows: string[];
  invoiceID?: string;
}) => {
  return axiosClient.post(`/api/user/sheets/clear/${sheetName}`, {
    rows,
    invoiceID,
  });
};

export const uploadSheetData = async ({
  file,
  sheetName,
}: {
  file: File;
  sheetName: string;
}) => {
  const formData = new FormData();

  formData.append("file", file);

  axiosClient.defaults.headers["Content-Type"] = "multipart/form-data";

  const res = await axiosClient.post(
    `/api/user/sheets/upload/${sheetName}`,
    formData,
  );

  axiosClient.defaults.headers["Content-Type"] = "application/json";

  return res;
};

export const updateUser = async (data: {
  username?: string;
  currentPassword?: string;
  newPassword?: string;
}) => {
  return axiosClient.put("/api/user", data);
};

export const getMonthlyLimits = async () => {
  return axiosClient.get("/api/user/monthly-limits");
};

export const deleteUser = async () => {
  return axiosClient.delete("/api/user");
};

export const getDashboardData = async () => {
  return axiosClient.get("/api/user/dashboard");
};

export const getOutstandingAmountByDueDate = async (filter: string) => {
  return axiosClient.get(
    `/api/user/dashboard/outstanding-amount?filter=${filter}`,
  );
};

export const getRevenueByMonth = async (month: string) => {
  return axiosClient.get(`/api/user/dashboard/revenue?month=${month}`);
};
