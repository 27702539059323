import moment from "moment";
import React from "react";

const UpcomingBirthdays = ({ data }: { data: any }) => {
  return (
    <div className="flex h-[16.5rem] flex-col gap-5 rounded-xl border p-5">
      <p className="font-semibold">
        Upcoming Birthdays ({moment().format("MMM YY")}) 🎂
      </p>
      {data.length === 0 && (
        <p className="text-center font-semibold">No upcoming birthdays</p>
      )}
      <div className="flex flex-col gap-5">
        {data.map((item: any, index: number) => (
          <>
            <div
              key={index}
              className={`flex items-center gap-5 text-sm ${
                item.day === moment().date() ? "font-bold" : ""
              }`}
            >
              <p className="flex-[0.5]">
                {moment(item.dateOfBirth).format("DD MMM")}
              </p>
              <p className="flex-[0.5] capitalize">
                {item.firstName} {item.lastName}{" "}
                {item.day === moment().date() && "🎊"}
              </p>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default UpcomingBirthdays;
