import { ReminderConfiguration } from "@/utils/constants";
import axiosClient from "./axios-client";

export const getReminderConfiguration = async () => {
  return axiosClient.get("/api/reminder-configuration");
};

export const updateReminderConfiguration = async (
  data: Partial<ReminderConfiguration>,
) => {
  return axiosClient.put("/api/reminder-configuration", data);
};
