const Help = () => {
  return (
    <div className="flex h-full flex-col items-center justify-center gap-3">
      <p className="text-xl font-semibold">Having an issue or need support?</p>
      <p className="text-lg">Just drop as email at</p>
      <a
        href="mailto:hello@finbite.in"
        className="rounded-full bg-primary px-6 py-3 text-white"
      >
        hello@finbite.in
      </a>
    </div>
  );
};

export default Help;
