import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import BusinessDetails from "./components/business-details";
import SelectEmailTemplate from "./components/select-email-template";
import IntervalDays from "./components/interval-days";
import { useLocation } from "react-router-dom";
import EmailSetup from "./components/email-setup/email-setup";
import { useContext } from "react";
import { EmailReminderContext } from "@/context/email-reminder-context";
import { UserContext } from "@/context/user-context";

const ReminderConfiguration = () => {
  const { reminderConfiguration } = useContext(EmailReminderContext);
  const { currentUser } = useContext(UserContext);

  const validTabs = [
    "business-details",
    "email-setup",
    "select-email-template",
    "interval-days",
  ];
  const location = useLocation();

  const showBusinessDetailsDot =
    !reminderConfiguration?.businessDetails?.businessName ||
    !reminderConfiguration?.businessDetails.email ||
    !reminderConfiguration?.businessDetails.mobileNumber;

  const showEmailSetupDot = !currentUser?.gmail?.mail;

  return (
    <Tabs
      defaultValue={
        validTabs.includes(location.hash.replace("#", ""))
          ? location.hash.replace("#", "")
          : "business-details"
      }
      className="w-full"
    >
      <div className="flex w-full items-center justify-center">
        <TabsList className="grid h-[200px] w-full grid-cols-1 justify-center gap-4 self-center md:h-fit md:w-fit md:grid-cols-2 lg:grid-cols-4">
          <TabsTrigger
            value="business-details"
            className="flex items-center gap-2"
          >
            {showBusinessDetailsDot && (
              <div className="h-[0.35rem] w-[0.35rem] rounded-full bg-destructive" />
            )}
            <p>Business Details</p>
          </TabsTrigger>
          <TabsTrigger value="email-setup" className="flex items-center gap-2">
            {showEmailSetupDot && (
              <div className="h-[0.35rem] w-[0.35rem] rounded-full bg-destructive" />
            )}
            <p>Email Setup</p>
          </TabsTrigger>
          <TabsTrigger
            value="select-email-template"
            disabled={showBusinessDetailsDot || showEmailSetupDot}
            className="flex items-center gap-2"
          >
            Email Template Preview
          </TabsTrigger>
          <TabsTrigger
            value="interval-days"
            disabled={showBusinessDetailsDot || showEmailSetupDot}
            className="flex items-center gap-2"
          >
            Interval Days & Time
          </TabsTrigger>
        </TabsList>
      </div>
      <div className="mt-10">
        <TabsContent value="business-details">
          <BusinessDetails />
        </TabsContent>
        <TabsContent value="email-setup">
          <EmailSetup />
        </TabsContent>
        <TabsContent value="select-email-template">
          <SelectEmailTemplate />
        </TabsContent>
        <TabsContent value="interval-days">
          <IntervalDays />
        </TabsContent>
      </div>
    </Tabs>
  );
};

export default ReminderConfiguration;
