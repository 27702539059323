import { getSingleSheetRow, updateSheetRow } from "@/api/user";
import StaffDetailsForm from "@/components/forms/staff-details-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { RotateCw } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import { useContext } from "react";
import { EmailReminderContext } from "@/context/email-reminder-context";

const EditStaffDetails = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { reminderConfiguration } = useContext(EmailReminderContext);

  const {
    data,
    isFetching,
  }: {
    data: any;
    isFetching: boolean;
  } = useQuery({
    queryKey: ["singleSheetRow", params.id],
    queryFn: async () =>
      getSingleSheetRow({
        sheetName: "Staff details",
        rowId: params.id!,
      }),
  });

  const mutation = useMutation({
    mutationFn: updateSheetRow,
    onSuccess: () => {
      navigate("/staff-details");
      toast.success("Staff details added successfully");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const onSubmit = (values: any) => {
    mutation.mutate({
      data: {
        ...values,
        reminderID: reminderConfiguration!._id,
      },
      sheetName: "Staff details",
      rowId: params.id!,
    });
  };

  if (isFetching) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <RotateCw size={16} className="mx-auto animate-spin" />
      </div>
    );
  }
  if (!data) {
    return null;
  }

  return (
    <StaffDetailsForm
      initialValues={data}
      onSubmit={onSubmit}
      isPending={mutation.isPending}
    />
  );
};

export default EditStaffDetails;
