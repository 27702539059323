import { getSingleSheetRow, updateSheetRow } from "@/api/user";
import ClientForm from "@/components/forms/client-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { RotateCw } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

const EditClient = () => {
  const navigate = useNavigate();
  const params = useParams();

  const {
    data,
    isFetching,
  }: {
    data: any;
    isFetching: boolean;
  } = useQuery({
    queryKey: ["singleSheetRow", params.id],
    queryFn: async () =>
      getSingleSheetRow({
        sheetName: "Client Database",
        rowId: params.id!,
      }),
  });

  const mutation = useMutation({
    mutationFn: updateSheetRow,
    onSuccess: () => {
      navigate("/client-list");
      toast.success("Client details updated successfully");
    },
    onError: (error: any) => {
      toast.error(error.message);
    },
  });

  const onSubmit = (data: any) => {
    mutation.mutate({
      data: data,
      sheetName: "Client Database",
      rowId: params.id!,
    });
  };

  if (isFetching) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <RotateCw size={16} className="mx-auto animate-spin" />
      </div>
    );
  }
  if (!data) {
    return null;
  }

  return (
    <ClientForm
      initialValues={data}
      onSubmit={onSubmit}
      isPending={mutation.isPending}
    />
  );
};

export default EditClient;
