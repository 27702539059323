import { addDataToSheet } from "@/api/user";
import ClientForm from "@/components/forms/client-form";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

const AddClient = () => {
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: addDataToSheet,
    onSuccess: () => {
      navigate("/client-list");
      toast.success("Client details added successfully");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const onSubmit = (data: any) => {
    mutation.mutate({
      data: data,
      sheetName: "Client Database",
    });
  };

  return <ClientForm onSubmit={onSubmit} isPending={mutation.isPending} />;
};
export default AddClient;
