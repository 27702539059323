import { UserContext } from "@/context/user-context";
import React, { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const AuthLayout = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);

  useEffect(() => {
    if (currentUser) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  if (currentUser) {
    return null;
  }

  return <Outlet />;
};

export default AuthLayout;
