import {
  getSheetClientIDs,
  getSingleSheetRow,
  updateSheetRow,
} from "@/api/user";
import InvoiceForm from "@/components/forms/invoice-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { RotateCw } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

const EditInvoice = () => {
  const params = useParams();
  const navigate = useNavigate();

  const {
    data,
    isFetching,
  }: {
    data: any;
    isFetching: boolean;
  } = useQuery({
    queryKey: ["singleSheetRow", params.id],
    queryFn: async () =>
      getSingleSheetRow({
        sheetName: "Payment Reminders",
        rowId: params.id!,
      }),
  });

  const {
    data: clientIDs,
    isFetching: isFetchingClientIDs,
  }: {
    data: any;
    isFetching: boolean;
  } = useQuery({
    queryKey: ["sheetData"],
    queryFn: async () => getSheetClientIDs(),
  });

  const mutation = useMutation({
    mutationFn: updateSheetRow,
    onSuccess: () => {
      navigate("/payment-reminders");
      toast.success("Invoice updated successfully");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const onSubmit = (values: any) => {
    // send array of strings to the mutation
    mutation.mutate({
      data: values,
      sheetName: "Payment Reminders",
      rowId: params.id!,
    });
  };

  if (isFetching || isFetchingClientIDs) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <RotateCw size={16} className="mx-auto animate-spin" />
      </div>
    );
  }
  if (!data) {
    return null;
  }

  return (
    <InvoiceForm
      clientIDs={clientIDs}
      invoiceId={params.id}
      initialValues={data}
      onSubmit={onSubmit}
      isPending={mutation.isPending}
    />
  );
};

export default EditInvoice;
