import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormField,
  FormItem,
  FormControl,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Country, State, City } from "country-state-city";
import PhoneInput from "react-phone-input-2";
import { ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { toTitleCase } from "@/utils/string-helpers";
import useEditableState from "@/hooks/use-editable-state";
import Combobox from "../ui/combo-box";
import DatePicker from "../ui/date-picker";
import { Checkbox } from "../ui/checkbox";
import moment from "moment";

const salutations1 = ["dear", "hello", "hey", "hi", "namaste", "respected"];

const salutations2 = [
  "accounts",
  "company name",
  "first last name",
  "first name",
  "last name",
  "madam",
  "sir",
  "sir/madam",
  "team",
];
const salutations3 = ["ji", "madam", "sir"];

const formSchema = z.object({
  clientID: z.string().min(1, "Client ID is required"),
  companyName: z.string().min(1, "Company Name is required"),
  authorizedPersonFirstName: z.string().min(1, "First Name is required"),
  authorizedPersonLastName: z.string().min(1, "Last Name is required"),
  salutation1: z.string(),
  salutation2: z.string(),
  salutation3: z.string(),
  email: z.string().email("Invalid email address"),
  mobileNumber: z.string().min(1, "Mobile Number is required"),
  addressLine1: z.string(),
  addressLine2: z.string().optional(),
  city: z.string(),
  state: z.string(),
  country: z.string(),
  pincode: z.string(),
  gstNumber: z.string(),
  panNumber: z.string(),
  dateOfOnboarding: z.date().optional(),
  onboardingEmail: z.string(),
});

type FormSchemaType = z.infer<typeof formSchema>;

const ClientForm = ({
  initialValues,
  isPending,
  onSubmit,
}: {
  initialValues?: FormSchemaType;
  isPending?: boolean;
  onSubmit: (data: any) => void;
}) => {
  const navigate = useNavigate();

  const [isEditable, setIsEditable] = useEditableState(initialValues);

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues
      ? {
          ...initialValues,
          dateOfOnboarding: initialValues.dateOfOnboarding
            ? new Date(initialValues.dateOfOnboarding)
            : undefined,
          onboardingEmail: "no",
        }
      : {
          clientID: "",
          companyName: "",
          authorizedPersonFirstName: "",
          authorizedPersonLastName: "",
          salutation1: "",
          salutation2: "",
          salutation3: "",
          email: "",
          mobileNumber: "+91",
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          country: "",
          pincode: "",
          gstNumber: "",
          panNumber: "",
          dateOfOnboarding: undefined,
          onboardingEmail: "no",
        },
  });

  const { setValue } = form;

  form.watch("country");
  form.watch("state");
  form.watch("city");

  return (
    <div className="container mx-auto rounded-md bg-white p-6 shadow-md">
      <div className="mb-5 flex flex-wrap items-center justify-between">
        <div className="mb-6 flex items-center gap-3">
          <ArrowLeft
            className="cursor-pointer rounded-full p-2 duration-150 hover:bg-gray-300"
            size={36}
            onClick={() => navigate(-1)}
          />
          <h2 className="text-2xl font-semibold">Client Details Form</h2>
        </div>
        {!isEditable && (
          <Button
            className="w-full sm:w-[12rem]"
            onClick={() => {
              setIsEditable(true);
            }}
          >
            Edit Data
          </Button>
        )}
      </div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit((e) => {
            onSubmit({
              ...e,
              dateOfOnboarding: moment(e.dateOfOnboarding).format("YYYY-MM-DD"),
            });
          })}
          className="space-y-6"
        >
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
            <FormField
              control={form.control}
              name="clientID"
              render={({ field }) => (
                <FormItem>
                  <label>Client ID</label>
                  <FormControl>
                    <Input
                      disabled={!isEditable}
                      placeholder="Client ID"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="companyName"
              render={({ field }) => (
                <FormItem>
                  <label>Company Name</label>
                  <FormControl>
                    <Input
                      disabled={!isEditable}
                      placeholder="Company Name"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="authorizedPersonFirstName"
              render={({ field }) => (
                <FormItem>
                  <label>First Name</label>
                  <FormControl>
                    <Input
                      disabled={!isEditable}
                      placeholder="First Name"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="authorizedPersonLastName"
              render={({ field }) => (
                <FormItem>
                  <label>Last Name</label>
                  <FormControl>
                    <Input
                      disabled={!isEditable}
                      placeholder="Last Name"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="salutation1"
              render={({ field }) => (
                <FormItem>
                  <label>Salutation 1</label>
                  <FormControl>
                    <Select
                      disabled={!isEditable}
                      {...field}
                      onValueChange={(e) =>
                        e === "None"
                          ? setValue("salutation1", "")
                          : setValue("salutation1", e)
                      }
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="-" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="None">
                          {/* You can use a space or a label like "None" */}
                          None
                        </SelectItem>
                        {salutations1.map((salutation: string) => (
                          <SelectItem value={salutation}>
                            {toTitleCase(salutation)}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="salutation2"
              render={({ field }) => (
                <FormItem>
                  <label>Salutation 2</label>
                  <FormControl>
                    <Select
                      disabled={!isEditable}
                      {...field}
                      onValueChange={(e) =>
                        e === "None"
                          ? setValue("salutation2", "")
                          : setValue("salutation2", e)
                      }
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="-" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="None">
                          {/* You can use a space or a label like "None" */}
                          None
                        </SelectItem>
                        {salutations2.map((salutation: string) => (
                          <SelectItem value={salutation}>
                            {toTitleCase(salutation)}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="salutation3"
              render={({ field }) => (
                <FormItem>
                  <label>Salutation 3</label>
                  <FormControl>
                    <Select
                      disabled={!isEditable}
                      {...field}
                      onValueChange={(e) =>
                        e === "None"
                          ? setValue("salutation3", "")
                          : setValue("salutation3", e)
                      }
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="-" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="None">
                          {/* You can use a space or a label like "None" */}
                          None
                        </SelectItem>
                        {salutations3.map((salutation: string) => (
                          <SelectItem value={salutation}>
                            {toTitleCase(salutation)}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <label>Email</label>
                  <FormControl>
                    <Input
                      disabled={!isEditable}
                      placeholder="Email"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="mobileNumber"
              render={({ field }) => (
                <FormItem>
                  <label>Mobile Number</label>
                  <FormControl>
                    <PhoneInput
                      {...field}
                      disabled={!isEditable}
                      inputStyle={{
                        minWidth: "none",
                        width: "100%",
                      }}
                      value={field.value}
                      onChange={(e) => {
                        setValue("mobileNumber", e);
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="country"
              render={({ field }) => (
                <FormItem>
                  <label>Country</label>
                  <FormControl>
                    {/* <Combobox
                      disabled={!isEditable}
                      placeholder="Country"
                      value={form.getValues("country")}
                      data={Country.getAllCountries().map((country) => {
                        return {
                          value: country.name.toLocaleLowerCase(),
                          label: country.name,
                        };
                      })}
                      onChange={(value: string) => {
                        setValue(
                          "country",
                          Country.getAllCountries().find(
                            (country) => country.name.toLowerCase() === value,
                          )?.isoCode || "",
                        );
                      }}
                    /> */}
                    <Select
                      disabled={!isEditable}
                      {...field}
                      onValueChange={(e) => {
                        setValue("country", e);
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="-" />
                      </SelectTrigger>
                      <SelectContent>
                        {Country.getAllCountries().map((country) => (
                          <SelectItem value={country.isoCode}>
                            {country.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="state"
              render={({ field }) => (
                <FormItem>
                  <label>State</label>
                  <FormControl>
                    {/* <Combobox
                      disabled={!isEditable}
                      placeholder="State"
                      value={form.getValues("state")}
                      data={State.getStatesOfCountry(
                        form.getValues("country"),
                      ).map((state) => {
                        return {
                          value: state.name.toLocaleLowerCase(),
                          label: state.name,
                        };
                      })}
                      onChange={(value: string) => {
                        setValue(
                          "state",
                          State.getStatesOfCountry(
                            form.getValues("country"),
                          ).find((state) => state.name.toLowerCase() === value)
                            ?.isoCode || "",
                        );
                      }}
                    /> */}
                    <Select
                      {...field}
                      disabled={!isEditable}
                      onValueChange={(e) => {
                        setValue("state", e);
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="-" />
                      </SelectTrigger>
                      <SelectContent>
                        {State.getStatesOfCountry(
                          form.getValues("country"),
                        ).map((state) => (
                          <SelectItem value={state.isoCode}>
                            {state.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="city"
              render={({ field }) => (
                <FormItem>
                  <label>City</label>
                  <FormControl>
                    {/* <Combobox
                      disabled={!isEditable}
                      placeholder="City"
                      value={form.getValues("city")}
                      data={City.getCitiesOfState(
                        form.getValues("country"),
                        form.getValues("state"),
                      ).map((city) => {
                        return {
                          value: city.name.toLocaleLowerCase(),
                          label: city.name,
                        };
                      })}
                      onChange={(value: string) => {
                        setValue(
                          "city",
                          City.getCitiesOfState(
                            form.getValues("country"),
                            form.getValues("state"),
                          ).find((city) => city.name.toLowerCase() === value)
                            ?.name || "",
                        );
                      }}
                    /> */}
                    <Select
                      disabled={!isEditable}
                      {...field}
                      onValueChange={(e) => {
                        setValue("city", e);
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="-" />
                      </SelectTrigger>
                      <SelectContent>
                        {City.getCitiesOfState(
                          form.getValues("country"),
                          form.getValues("state"),
                        ).map((city) => (
                          <SelectItem value={city.name}>{city.name}</SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="addressLine1"
              render={({ field }) => (
                <FormItem>
                  <label>Address Line 1</label>
                  <FormControl>
                    <Input disabled={!isEditable} placeholder="-" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="addressLine2"
              render={({ field }) => (
                <FormItem>
                  <label>Address Line 2</label>
                  <FormControl>
                    <Input disabled={!isEditable} placeholder="-" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="pincode"
              render={({ field }) => (
                <FormItem>
                  <label>Pincode</label>
                  <FormControl>
                    <Input disabled={!isEditable} placeholder="-" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="gstNumber"
              render={({ field }) => (
                <FormItem>
                  <label>GST Number</label>
                  <FormControl>
                    <Input disabled={!isEditable} placeholder="-" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="panNumber"
              render={({ field }) => (
                <FormItem>
                  <label>PAN Number</label>
                  <FormControl>
                    <Input disabled={!isEditable} placeholder="-" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="dateOfOnboarding"
              render={({ field }) => (
                <FormItem>
                  <label>Date of Onboarding</label>
                  <FormControl>
                    <DatePicker
                      date={field.value ? new Date(field.value) : undefined}
                      disabled={!isEditable}
                      placeholder="-"
                      onChange={(date) => field.onChange(date)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div>
              <FormField
                control={form.control}
                name="onboardingEmail"
                render={({ field }) => (
                  <FormItem className="mt-6 flex items-center gap-2">
                    <FormControl className="mt-2">
                      <Checkbox
                        disabled={!isEditable}
                        {...field}
                        value={field.value!.toString()}
                        onCheckedChange={(value) => {
                          field.onChange(value === true ? "yes" : "no");
                        }}
                        checked={field.value === "yes"}
                      />
                    </FormControl>
                    <label>Onboarding Email</label>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <p className="text-xs text-secondary">
                (Select only if customer is new)
              </p>
            </div>
          </div>
          {isEditable && (
            <div className="flex justify-end">
              <Button
                type="submit"
                className="w-full sm:w-[12rem]"
                disabled={isPending}
                loading={isPending}
              >
                {initialValues ? "Update" : "Add"} Client
              </Button>
            </div>
          )}
        </form>
      </Form>
    </div>
  );
};

export default ClientForm;
