import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormField,
  FormItem,
  FormControl,
  FormMessage,
} from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Country, State, City } from "country-state-city";
import { useContext } from "react";
import { EmailReminderContext } from "@/context/email-reminder-context";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const formSchema = z.object({
  businessName: z.string().min(3, "Name should be at least 3 characters"),
  email: z.string().email("Invalid email address").min(1, "Email is required"),
  mobileNumber: z.string().min(10, "Mobile Number is required"),
  addressLine1: z.string(),
  addressLine2: z.string().optional(),
  city: z.string(),
  state: z.string(),
  country: z.string(),
  pincode: z.string(),
  authorizedPersonName: z.string(),
  designation: z.string(),
  gstNumber: z.string().optional(),
  bankDetails: z.string().optional(),
});

const BusinessDetails = () => {
  const { updateReminderMutation, reminderConfiguration } =
    useContext(EmailReminderContext);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      businessName: reminderConfiguration?.businessDetails?.businessName || "",
      email: reminderConfiguration?.businessDetails?.email || "",
      mobileNumber:
        reminderConfiguration?.businessDetails?.mobileNumber || "+91",
      addressLine1: reminderConfiguration?.businessDetails?.addressLine1 || "",
      addressLine2: reminderConfiguration?.businessDetails?.addressLine2 || "",
      city: reminderConfiguration?.businessDetails?.city || "",
      state: reminderConfiguration?.businessDetails?.state || "",
      country: reminderConfiguration?.businessDetails?.country || "",
      pincode: reminderConfiguration?.businessDetails?.pincode || "",
      authorizedPersonName:
        reminderConfiguration?.businessDetails?.authorizedPersonName || "",
      designation: reminderConfiguration?.businessDetails?.designation || "",
      gstNumber: reminderConfiguration?.businessDetails?.gstNumber || "",
      bankDetails: reminderConfiguration?.businessDetails?.bankDetails || "",
    },
  });

  const { setValue } = form;

  form.watch("country");
  form.watch("state");
  form.watch("city");

  const onSubmit = (values: any) => {
    updateReminderMutation.mutate({
      businessDetails: values,
    });
  };

  return (
    <div className="mx-auto rounded-md bg-white p-6 shadow-md">
      <h2 className="mb-6 text-2xl font-semibold">Business Information Form</h2>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
            <FormField
              control={form.control}
              name="businessName"
              render={({ field }) => (
                <FormItem>
                  <label>Business Name</label>
                  <FormControl>
                    <Input placeholder="Business Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <label>Email ID</label>
                  <FormControl>
                    <Input placeholder="example@example.com" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="mobileNumber"
              render={({ field }) => (
                <FormItem>
                  <label>Mobile Number</label>
                  <FormControl>
                    <PhoneInput
                      inputStyle={{
                        minWidth: "none",
                        width: "100%",
                      }}
                      value={field.value}
                      onChange={(e) => {
                        setValue("mobileNumber", e);
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="addressLine1"
              render={({ field }) => (
                <FormItem>
                  <label>Address Line 1</label>
                  <FormControl>
                    <Input placeholder="-" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="addressLine2"
              render={({ field }) => (
                <FormItem>
                  <label>Address Line 2</label>
                  <FormControl>
                    <Input placeholder="-" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="country"
              render={({ field }) => (
                <FormItem>
                  <label>Country</label>
                  <FormControl>
                    <Select
                      {...field}
                      onValueChange={(e) => {
                        setValue("country", e);
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="-" />
                      </SelectTrigger>
                      <SelectContent>
                        {Country.getAllCountries().map((country) => (
                          <SelectItem value={country.isoCode}>
                            {country.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="state"
              render={({ field }) => (
                <FormItem>
                  <label>State</label>
                  <FormControl>
                    <Select
                      {...field}
                      onValueChange={(e) => {
                        setValue("state", e);
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="-" />
                      </SelectTrigger>
                      <SelectContent>
                        {State.getStatesOfCountry(
                          form.getValues("country"),
                        ).map((state) => (
                          <SelectItem value={state.isoCode}>
                            {state.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="city"
              render={({ field }) => (
                <FormItem>
                  <label>City</label>
                  <FormControl>
                    <Select
                      {...field}
                      onValueChange={(e) => {
                        setValue("city", e);
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="-" />
                      </SelectTrigger>
                      <SelectContent>
                        {City.getCitiesOfState(
                          form.getValues("country"),
                          form.getValues("state"),
                        ).map((city) => (
                          <SelectItem value={city.name}>{city.name}</SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="pincode"
              render={({ field }) => (
                <FormItem>
                  <label>Pincode</label>
                  <FormControl>
                    <Input placeholder="-" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="authorizedPersonName"
              render={({ field }) => (
                <FormItem>
                  <label>Authorized Person Name</label>
                  <FormControl>
                    <Input placeholder="-" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="designation"
              render={({ field }) => (
                <FormItem>
                  <label>Designation</label>
                  <FormControl>
                    <Input placeholder="-" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="gstNumber"
              render={({ field }) => (
                <FormItem>
                  <label>GST Number</label>
                  <FormControl>
                    <Input placeholder="-" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="bankDetails"
              render={({ field }) => (
                <FormItem>
                  <label>Bank Details</label>
                  <FormControl>
                    <Textarea
                      placeholder="-"
                      {...field}
                      className="resize-none"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="flex justify-end">
            <Button
              className="min-w-[10rem]"
              type="submit"
              disabled={updateReminderMutation.isPending}
              loading={updateReminderMutation.isPending}
            >
              Save Configuration
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default BusinessDetails;
