import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormField,
  FormItem,
  FormControl,
  FormMessage,
} from "@/components/ui/form";
import { ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";

import useEditableState from "@/hooks/use-editable-state";
import DatePicker from "../ui/date-picker";
import moment from "moment";

// Define the schema and TypeScript type
const formSchema = z.object({
  paymentDate: z.date({ message: "Payment date is required" }),
  amountPaid: z.number().min(1, "Amount paid is required"),
});

type FormSchemaType = z.infer<typeof formSchema>;

const PaymentReceiptForm = ({
  initialValues,
  isPending,
  onSubmit,
}: {
  initialValues?: {
    paymentDate: Date;
    amountPaid: number;
  };
  isPending?: boolean;
  onSubmit: (values: any) => void;
}) => {
  const navigate = useNavigate();

  const [isEditable, setIsEditable] = useEditableState(initialValues);

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues
      ? {
          ...initialValues,
          paymentDate: new Date(initialValues.paymentDate),
        }
      : {
          paymentDate: undefined,
          amountPaid: 0,
        },
  });

  return (
    <div className="container mx-auto rounded-md bg-white p-6 shadow-md">
      <div className="mb-5 flex flex-wrap items-center justify-between">
        <div className="mb-6 flex items-center gap-3">
          <ArrowLeft
            className="cursor-pointer rounded-full p-2 duration-150 hover:bg-gray-300"
            size={36}
            onClick={() => navigate(-1)}
          />
          <h2 className="text-2xl font-semibold">Payment Receipt Form</h2>
        </div>
        {!isEditable && (
          <Button
            className="w-full sm:w-[12rem]"
            onClick={() => {
              setIsEditable(true);
            }}
          >
            Edit Data
          </Button>
        )}
      </div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit((e) => {
            onSubmit({
              ...e,
              paymentDate: moment(e.paymentDate).format("YYYY-MM-DD"),
            });
          })}
          className="space-y-6"
        >
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
            <FormField
              control={form.control}
              name="paymentDate"
              render={({ field }) => (
                <FormItem>
                  <label>Payment Date</label>
                  <FormControl>
                    <DatePicker
                      disabled={!isEditable}
                      date={field.value ? new Date(field.value) : undefined}
                      onChange={(date) => {
                        field.onChange(date);
                      }}
                      placeholder="Select Date"
                    />
                    {/* <Input
                      disabled={!isEditable}
                      placeholder="Payment Date"
                      type="date"
                      {...field}
                      value={
                        field.value
                          ? new Date(field.value).toISOString().split("T")[0]
                          : ""
                      }
                    /> */}
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="amountPaid"
              render={({ field }) => (
                <FormItem>
                  <label>Amount Paid</label>
                  <FormControl>
                    <Input
                      disabled={!isEditable}
                      placeholder="Amount Paid"
                      {...field}
                      type="number"
                      onChange={(e) => {
                        // allow maximum of 2 decimal places
                        const value = e.target.value;
                        const regex = /^[0-9]+(\.[0-9]{0,2})?$/;
                        if (regex.test(value)) {
                          field.onChange(parseFloat(value));
                        }
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          {isEditable && (
            <div className="flex justify-end">
              <Button
                className="sm:w-[8rem]"
                type="submit"
                disabled={isPending}
                loading={isPending}
              >
                {initialValues ? "Update" : "Create"} Receipt
              </Button>
            </div>
          )}
        </form>
      </Form>
    </div>
  );
};

export default PaymentReceiptForm;
