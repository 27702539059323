import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// Define the return type of the hook
type UseEditableStateReturn = [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
];

const useEditableState = (initialValues: any): UseEditableStateReturn => {
  const location = useLocation();
  const { hash } = location;

  const [isEditable, setIsEditable] = useState<boolean>(false);

  useEffect(() => {
    if (!(hash === "#noedit" && initialValues)) {
      setIsEditable(true);
    }
  }, [initialValues, hash]);

  return [isEditable, setIsEditable];
};

export default useEditableState;
