import { getDashboardData } from "@/api/user";
import { useQuery } from "@tanstack/react-query";
import { RotateCw } from "lucide-react";
import TopOutstandingChart from "./components/top-outstanding-chart";
import UpcomingBirthdays from "./components/upcoming-birthdays";
import CustomerWiseRevenueChart from "./components/customer-wise-revenue-chart";
import PercentOutRevenue from "./components/percent-out-revenue";
import OutstandingByDate from "./components/outstanding-by-date";
import HomeCards from "./components/home-cards";
import RevenueByMonth from "./components/revenue-by-month";

const Home = () => {
  const { data, isLoading }: { data: any; isLoading: boolean } = useQuery({
    queryKey: ["home"],
    queryFn: getDashboardData,
  });

  if (isLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <RotateCw className="h-4 w-4 animate-spin" />
      </div>
    );
  }

  return (
    <div className="mx-5 grid gap-4 font-montserrat">
      <HomeCards data={data} />
      <div className="grid grid-cols-12 gap-4 3xl:grid-cols-10">
        <div className="col-span-12 lg:col-span-6 2xl:col-span-4">
          <TopOutstandingChart
            data={data.top10CustomersByOutstandingAmount}
            maxValue={data.top10CustomersByOutstandingAmount.reduce(
              (acc: number, item: any) => {
                return acc > item.totalOutstandingAmount
                  ? acc
                  : item.totalOutstandingAmount;
              },
              0,
            )}
          />
        </div>
        <div className="col-span-12 flex flex-col gap-4 lg:col-span-6 2xl:col-span-5 3xl:col-span-4">
          <div>
            <OutstandingByDate />
          </div>
          <div className="flex flex-col items-stretch gap-4 2xl:flex-row">
            <div className="2xl:flex-[0.7] 3xl:flex-[0.5]">
              <CustomerWiseRevenueChart data={data.customerWiseRevenue} />
            </div>
            <div className="h-full 2xl:flex-[0.3] 3xl:flex-[0.5]">
              <RevenueByMonth />
            </div>
          </div>
        </div>
        <div className="col-span-12 flex flex-col gap-4 2xl:col-span-3 3xl:col-span-2">
          <div>
            <UpcomingBirthdays data={data.upcomingBirthdays} />
          </div>
          <div>
            <PercentOutRevenue
              data={[
                {
                  outstanding: data.percentageOutstandingRevenue,
                  paid: 100 - data.percentageOutstandingRevenue,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
