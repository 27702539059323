import { useContext } from "react";
import { Button } from "@/components/ui/button";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  FormField,
  Form,
  FormControl,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { EmailReminderContext } from "@/context/email-reminder-context";
import { Input } from "@/components/ui/input";

const formSchema = z.object({
  email: z.string().refine((v) => v === "" || v.includes("@"), {
    message: "Please enter a valid email",
  }),
});

const AddCc = () => {
  const { updateReminderMutation, reminderConfiguration } =
    useContext(EmailReminderContext);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: reminderConfiguration?.cc,
    },
  });

  const onSubmit = (values: { email?: string }) => {
    updateReminderMutation.mutate({
      cc: values.email,
    });
  };

  return (
    <>
      <div className="mt-8 w-full max-w-lg rounded-lg bg-white p-6 shadow-lg">
        <h2 className="mb-4 text-lg font-semibold text-gray-800">
          Add CC to send reminders
        </h2>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input {...field} placeholder="Enter email" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex justify-end">
              <Button
                type="submit"
                className="mt-6 w-fit min-w-[10rem]"
                disabled={updateReminderMutation.isPending}
                loading={updateReminderMutation.isPending}
              >
                Save Configuration
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </>
  );
};

export default AddCc;
