import { Routes, Route, useSearchParams } from "react-router-dom";
import Home from "./pages/home/home";
import SignIn from "./pages/auth/sign-in/sign-in";
import SignUp from "./pages/auth/sign-up/sign-up";
import AuthLayout from "./layouts/auth-layout";
import MainLayout from "./layouts/main-layout";
import ReminderConfiguration from "./pages/reminder-configuration/reminder-configuration";
import AddClient from "./pages/add-client/add-client";
import AddPaymentReceipt from "./pages/add-payment-receipt/add-payment-receipt";
import AddStaffDetails from "./pages/add-staff-details/add-staff-details";
import PaymentReceipts from "./pages/payment-receipts/payment-receipts";
import ClientList from "./pages/client-list/client-list";
import StaffDetails from "./pages/staff-details/staff-details";
import Invoices from "./pages/invoices/invoices";
import AddInvoice from "./pages/add-invoice/add-invoice";
import EditPaymentReceipt from "./pages/edit-payment-receipt/edit-payment-receipt";
import EditClient from "./pages/edit-client/edit-client";
import EditStaffDetails from "./pages/edit-staff-details/edit-staff-details";
import EditInvoice from "./pages/edit-invoice/edit-invoice";
import ResetPassword from "./pages/auth/reset-password/reset-password";
import Settings from "./pages/settings/settings";
import Help from "./pages/help/help";
import { useQuery } from "@tanstack/react-query";
import { verifyEmail } from "./api/auth";
import { useContext, useEffect } from "react";
import { UserContext } from "./context/user-context";

function App() {
  const [searchParams] = useSearchParams();

  const { currentUser, setCurrentUser } = useContext(UserContext);

  const { status } = useQuery({
    queryKey: ["user", searchParams.get("id")],
    queryFn: () =>
      verifyEmail({
        token: searchParams.get("token") || "",
        userId: searchParams.get("userID") || "",
      }),

    enabled: !!searchParams.get("token") && !!searchParams.get("userID"),
  });

  useEffect(() => {
    if (status === "success" && currentUser) {
      setCurrentUser({
        ...currentUser!,
        isVerified: true,
      });
    }
  }, [status]);

  return (
    <>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/" element={<Home />} />

          <Route path="/payment-receipts" element={<PaymentReceipts />} />
          <Route path="/client-list" element={<ClientList />} />
          <Route path="/staff-details" element={<StaffDetails />} />
          <Route path="/payment-reminders" element={<Invoices />} />

          <Route
            path="/payment-receipts/:invoiceId/add"
            element={<AddPaymentReceipt />}
          />
          <Route
            path="/payment-receipts/:invoiceId/edit/:paymentId"
            element={<EditPaymentReceipt />}
          />

          <Route path="/client-list/:id" element={<EditClient />} />
          <Route path="/staff-details/:id" element={<EditStaffDetails />} />
          <Route path="/payment-reminders/:id" element={<EditInvoice />} />
          <Route path="/client-list/add" element={<AddClient />} />
          <Route path="/staff-details/add" element={<AddStaffDetails />} />
          <Route path="/payment-reminders/add" element={<AddInvoice />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/help" element={<Help />} />

          <Route
            path="/reminder-configuration"
            element={<ReminderConfiguration />}
          />
        </Route>
      </Routes>
    </>
  );
}

export default App;
