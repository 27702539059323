"use client";

import { Label, PolarRadiusAxis, RadialBar, RadialBarChart } from "recharts";

import { Card, CardContent } from "@/components/ui/card";
import { ChartConfig, ChartContainer } from "@/components/ui/chart";

const chartConfig = {
  desktop: {
    label: "Desktop",
    color: "#e9f8f3",
  },
  mobile: {
    label: "Mobile",
    color: "#28b78c",
  },
} satisfies ChartConfig;

const PercentOutRevenue = ({ data }: { data: any }) => {
  return (
    <div className="flex max-h-[13rem] flex-col gap-5 overflow-hidden rounded-xl border p-5">
      <p className="text-center">% of Total Revenue Outstanding</p>
      <Card className="flex flex-col border-0">
        <CardContent className="flex flex-1 items-center pb-0">
          <ChartContainer
            config={chartConfig}
            className="relative -top-10 mx-auto aspect-square w-full max-w-[250px]"
          >
            <RadialBarChart
              data={data}
              endAngle={180}
              innerRadius={80}
              outerRadius={150}
            >
              <PolarRadiusAxis tick={false} tickLine={false} axisLine={false}>
                <Label
                  content={({ viewBox }) => {
                    if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                      return (
                        <text x={viewBox.cx} y={viewBox.cy} textAnchor="middle">
                          <tspan
                            x={viewBox.cx}
                            y={(viewBox.cy || 0) - 16}
                            className="fill-foreground text-2xl font-bold"
                          >
                            {data[0].outstanding}%
                          </tspan>
                        </text>
                      );
                    }
                  }}
                />
              </PolarRadiusAxis>
              <RadialBar
                dataKey="paid"
                stackId="a"
                cornerRadius={0}
                fill="var(--color-desktop)"
                className="stroke-transparent stroke-2"
              />
              <RadialBar
                dataKey="outstanding"
                fill="var(--color-mobile)"
                stackId="a"
                cornerRadius={0}
                className="stroke-transparent stroke-2"
              />
            </RadialBarChart>
          </ChartContainer>
        </CardContent>
      </Card>
    </div>
  );
};

export default PercentOutRevenue;
