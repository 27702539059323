import { addDataToSheet } from "@/api/user";
import StaffDetailsForm from "@/components/forms/staff-details-form";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { useContext } from "react";
import { EmailReminderContext } from "@/context/email-reminder-context";

const AddStaffDetails = () => {
  const navigate = useNavigate();

  const { reminderConfiguration } = useContext(EmailReminderContext);

  const mutation = useMutation({
    mutationFn: addDataToSheet,
    onSuccess: () => {
      navigate("/staff-details");
      toast.success("Staff details added successfully");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const onSubmit = (values: any) => {
    // send array of strings to the mutation
    mutation.mutate({
      data: { ...values, reminderID: reminderConfiguration!._id },
      sheetName: "Staff details",
    });
  };

  return (
    <StaffDetailsForm onSubmit={onSubmit} isPending={mutation.isPending} />
  );
};

export default AddStaffDetails;
