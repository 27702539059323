import { Progress } from "@/components/ui/progress";
import CurrencyFormat from "react-currency-format";

const TopOutstandingChart = ({
  data,
  maxValue,
}: {
  data: any;
  maxValue: number;
}) => {
  return (
    <div className="flex h-[31rem] flex-col gap-5 rounded-xl border p-5">
      <p className="font-semibold">Top 10 customers by outstanding amount</p>
      {data.length === 0 && (
        <p className="text-center font-semibold">No outstanding amount</p>
      )}
      <div className="flex flex-col gap-[0.875rem]">
        {data.map((item: any) => {
          const percentage = (item.totalOutstandingAmount / maxValue) * 100;

          return (
            <>
              <div>
                <div className="flex items-center justify-between text-xs">
                  <p>{item.companyName}</p>
                  <p>
                    <CurrencyFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      value={Number(item.totalOutstandingAmount).toFixed(0)}
                      thousandSpacing="2s"
                      prefix={"₹"}
                      renderText={(value) => <p>{value}</p>}
                    />
                  </p>
                </div>
                <Progress
                  value={percentage}
                  className="!h-3 bg-gray-300"
                  customColor="#28b78c"
                />
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default TopOutstandingChart;
