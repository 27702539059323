import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useLocation, useSearchParams } from "react-router-dom";
import ProfileTab from "./components/profile-tab";
import PlanTab from "./components/plan-tab";
import PasswordTab from "./components/paassword-tab";
import { useContext, useEffect } from "react";
import { UserContext } from "@/context/user-context";
import { useQuery } from "@tanstack/react-query";
import { verifyEmail } from "@/api/auth";

const Settings = () => {
  const validTabs = ["profile", "plan", "password"];
  const location = useLocation();

  const { currentUser } = useContext(UserContext);

  return (
    <Tabs
      defaultValue={
        validTabs.includes(location.hash.replace("#", ""))
          ? location.hash.replace("#", "")
          : "profile"
      }
      className="w-full"
    >
      <div className="flex w-full items-center justify-center">
        <TabsList className="grid h-[150px] w-full grid-cols-1 justify-center gap-4 self-center sm:h-fit sm:w-fit sm:grid-cols-3">
          <TabsTrigger
            value="profile"
            className="flex items-center gap-2"
            disabled={!currentUser?.isVerified}
          >
            Profile
          </TabsTrigger>
          <TabsTrigger
            className="flex items-center gap-2"
            value="plan"
            disabled={!currentUser?.isVerified}
          >
            Plan
          </TabsTrigger>

          <TabsTrigger
            className="flex items-center gap-2"
            value="password"
            disabled={!currentUser?.isVerified}
          >
            Password
          </TabsTrigger>
        </TabsList>
      </div>
      <div className="mt-10">
        <TabsContent value="profile">
          <ProfileTab />
        </TabsContent>
        <TabsContent value="plan">
          <PlanTab />
        </TabsContent>

        <TabsContent value="password">
          <PasswordTab />
        </TabsContent>
      </div>
    </Tabs>
  );
};

export default Settings;
