"use client";
import { logout } from "@/api/auth";
import {
  sidebarSheetsRoutes,
  sidebarSettingsRoutes,
} from "@/lib/constants/routes";
import { useMutation } from "@tanstack/react-query";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import parse from "html-react-parser";
import { UserContext } from "@/context/user-context";
import { LogOut, Menu, RotateCw } from "lucide-react";
import Logo from "@/assets/icons/logo.svg";
import { Progress } from "../ui/progress";
import { getMonthlyLimits } from "@/api/user";
import { EmailReminderContext } from "@/context/email-reminder-context";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import useClickOutside from "@/hooks/use-click-outside";

const Sidebar = () => {
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const { currentUser, setCurrentUser } = useContext(UserContext);
  const { reminderConfiguration } = useContext(EmailReminderContext);

  const pathname = useLocation().pathname;

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const mutation = useMutation({
    mutationFn: logout,
    onSuccess: () => {
      window.location.reload();
    },
    onError: () => {},
  });

  const handleLogout = () => {
    mutation.mutate();
  };

  const monthlyLimitMutation = useMutation({
    mutationFn: getMonthlyLimits,
    onSuccess: (res: any) => {
      setCurrentUser({
        ...currentUser!,
        monthlyLimit: res.monthlyLimit,
      });
    },
    onError: () => {},
  });

  const handleGetMonthlyLimits = () => {
    monthlyLimitMutation.mutate();
  };

  const closeSidebar = () => {
    if (window.innerWidth < 768) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    if (window.innerWidth >= 768) {
      setIsSidebarOpen(true);
    }
  }, []);

  useClickOutside(sidebarRef, () => {
    closeSidebar();
  });

  const showReminderRedDot =
    !reminderConfiguration?.businessDetails?.businessName ||
    !reminderConfiguration.businessDetails?.email ||
    !reminderConfiguration.businessDetails?.mobileNumber ||
    !currentUser?.gmail?.mail;

  const showSettingsRedDot = !currentUser?.isVerified;

  return (
    <div
      className={`noscroll fixed top-0 z-[20] flex h-full flex-shrink-0 flex-col items-center overflow-scroll bg-white pt-5 text-[#404040] shadow-xl transition-all duration-300 md:w-[18.125rem] 2xl:w-[19.375rem] 2xl:pt-10 ${
        isSidebarOpen
          ? "left-0 w-[19.375rem]"
          : "-left-[19.375rem] !w-0 md:left-0 md:w-[18.125rem]"
      }`}
      ref={sidebarRef}
    >
      {!isSidebarOpen && (
        <div className="fixed left-5 top-5">
          <Menu
            className={`cursor-pointer text-black`}
            color="black"
            height={25}
            width={25}
            onClick={toggleSidebar}
          />
        </div>
      )}
      <div
        className={`mb-[1.625rem] flex w-full justify-end px-[1.875rem] pb-[0.8125rem] sm:hidden`}
      >
        <Menu
          className={`cursor-pointer text-black`}
          color="black"
          height={25}
          width={25}
          onClick={toggleSidebar}
        />
      </div>

      <div
        className={`mt-5 flex w-full justify-center px-[1.875rem] pb-6 lg:px-5 2xl:px-[1.875rem]`}
      >
        <img src={Logo} alt="logo" />
      </div>

      <div className="relative flex h-full w-full flex-col justify-between gap-5">
        <div className="w-full gap-4 divide-y">
          <div className="relative mb-5 flex w-full flex-col gap-3 px-5">
            {sidebarSheetsRoutes.map((route, index) => (
              <Link
                onClick={closeSidebar}
                key={index}
                to={route.path}
                className={`relative mx-auto flex w-full justify-start gap-[1.25rem] rounded-lg px-5 py-3 text-sm hover:bg-[#F9FAFB] ${
                  route.path === pathname ? "font-bold" : ""
                } ${
                  (showReminderRedDot || showSettingsRedDot) &&
                  "pointer-events-none opacity-70"
                }`}
              >
                {route.path === pathname && (
                  <div className="absolute left-1 top-[19.5px] h-[0.35rem] w-[0.35rem] rounded-full bg-primary" />
                )}
                {parse(route.path === pathname ? route.iconActive : route.icon)}
                {route.name}
              </Link>
            ))}
          </div>
          <div className="relative flex w-full flex-col gap-3 px-5 pt-5">
            {sidebarSettingsRoutes.map((route, index) => (
              <Link
                onClick={closeSidebar}
                key={index}
                to={route.path}
                className={`relative mx-auto flex w-full justify-start gap-[1.25rem] rounded-lg px-5 py-3 text-sm hover:bg-[#F9FAFB] ${
                  route.path === pathname ? "font-bold" : ""
                }`}
              >
                {(route.path === pathname ||
                  (route.path === "/reminder-configuration" &&
                    showReminderRedDot)) && (
                  <>
                    <div
                      className={`absolute left-1 top-[19.5px] h-[0.35rem] w-[0.35rem] rounded-full ${
                        (route.path === "/settings" && showSettingsRedDot) ||
                        (route.path === "/reminder-configuration" &&
                          showReminderRedDot)
                          ? "bg-destructive"
                          : "bg-primary"
                      }`}
                    />
                  </>
                )}
                {/* {(route.path === pathname ||
                  (route.path === "/reminder-configuration" &&
                    showReminderRedDot)) &&
                  ((showReminderRedDot &&
                    pathname === "/reminder-configuration") ||
                    (showSettingsRedDot && pathname === "/settings")) && (
                    <div
                      className={`absolute left-1 top-[19.5px] h-[0.35rem] w-[0.35rem] rounded-full ${
                        (route.path === "/settings" && showSettingsRedDot) ||
                        (route.path === "/reminder-configuration" &&
                          showReminderRedDot)
                          ? "bg-destructive"
                          : "bg-primary"
                      }`}
                    />
                  )} */}
                {parse(route.path === pathname ? route.iconActive : route.icon)}
                {route.name}
              </Link>
            ))}
          </div>
        </div>
        <div className="mx-4 flex flex-col gap-5 rounded-lg border p-3">
          <RotateCw
            size={12}
            className={`cursor-pointer self-end ${monthlyLimitMutation.isPending ? "pointer-events-none animate-spin" : ""}`}
            onClick={handleGetMonthlyLimits}
          />
          {(currentUser?.subscription?.planCode ===
            "email-whatsapp-automations-annual-save-1389" ||
            currentUser?.subscription?.planCode ===
              "email-whatsapp-automations-monthly" ||
            currentUser?.isTrial) && (
            <div className="flex flex-col gap-1">
              <p className="text-xs">
                Whatsapp Messages Used (
                {Number(
                  currentUser?.isTrial
                    ? process.env.REACT_APP_TRIAL_WHATSAPP_LIMIT
                    : process.env.REACT_APP_MONTHLY_WHATSAPP_LIMIT,
                ) - currentUser?.monthlyLimit.whatsapp!}
                /
                {currentUser?.isTrial
                  ? process.env.REACT_APP_TRIAL_WHATSAPP_LIMIT
                  : process.env.REACT_APP_MONTHLY_WHATSAPP_LIMIT}
                )
              </p>
              <Progress
                className="!h-2 bg-gray-300"
                customColor="#28b78c"
                value={
                  ((Number(
                    currentUser?.isTrial
                      ? process.env.REACT_APP_TRIAL_WHATSAPP_LIMIT
                      : process.env.REACT_APP_MONTHLY_WHATSAPP_LIMIT,
                  ) -
                    currentUser?.monthlyLimit.whatsapp!) /
                    Number(
                      currentUser?.isTrial
                        ? process.env.REACT_APP_TRIAL_WHATSAPP_LIMIT
                        : process.env.REACT_APP_MONTHLY_WHATSAPP_LIMIT!,
                    )) *
                  100
                }
              />
            </div>
          )}
          <div className="flex flex-col gap-1">
            <p className="text-xs">
              Emails Used (
              {Number(
                currentUser?.isTrial
                  ? process.env.REACT_APP_TRIAL_EMAIL_LIMIT
                  : process.env.REACT_APP_MONTHLY_EMAIL_LIMIT,
              ) - currentUser?.monthlyLimit.email!}
              /
              {currentUser?.isTrial
                ? process.env.REACT_APP_TRIAL_EMAIL_LIMIT
                : process.env.REACT_APP_MONTHLY_EMAIL_LIMIT}
              )
            </p>
            <Progress
              className="!h-2 bg-gray-300"
              customColor="#28b78c"
              value={
                ((Number(
                  currentUser?.isTrial
                    ? process.env.REACT_APP_TRIAL_EMAIL_LIMIT
                    : process.env.REACT_APP_MONTHLY_EMAIL_LIMIT,
                ) -
                  currentUser?.monthlyLimit.email!) /
                  Number(
                    currentUser?.isTrial
                      ? process.env.REACT_APP_TRIAL_EMAIL_LIMIT
                      : process.env.REACT_APP_MONTHLY_EMAIL_LIMIT!,
                  )) *
                100
              }
            />
          </div>
        </div>
        <div
          className="flex items-center justify-between gap-4 border-t p-4 px-5 transition-all duration-300"
          style={{ filter: "drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2))" }}
        >
          <div className="flex items-center gap-3">
            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-primary text-lg font-bold text-white">
              {currentUser?.username.charAt(0).toUpperCase()}
            </div>
            <h1 className="max-w-[7.25rem] truncate text-sm font-bold leading-tight">
              {currentUser?.username}
            </h1>
          </div>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <div
                  role="button"
                  className="text-destructive"
                  onClick={handleLogout}
                >
                  <LogOut size={16} />
                </div>
              </TooltipTrigger>
              <TooltipContent className="relative -left-3">
                <p className="text-destructive">Logout</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
