import axiosClient from "./axios-client";

export const getEmailTemplates = async () => {
  return axiosClient.get("/api/email-template/all");
};

export const sendTestMail = async ({
  to,
  type,
}: {
  to: string;
  type: string;
}) => {
  return axiosClient.post(`/api/email-template/send-test-email/${type}`, {
    to,
  });
};
