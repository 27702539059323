"use client";

import * as React from "react";
import { DayPicker, getDefaultClassNames } from "react-day-picker";
import "react-day-picker/style.css";
import { cn } from "@/lib/utils";

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
  setIsOpen: (isOpen: boolean) => void;
};

function Calendar({
  className,
  showOutsideDays = true,
  setIsOpen,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      onDayClick={() => setIsOpen(false)}
      captionLayout="dropdown"
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      classNames={{
        caption_label: "text-sm font-medium",
        selected: "font-bold !bg-primary !text-white",
        chevron: `${getDefaultClassNames().chevron} !fill-black w-4 h-4`,
        day: `${getDefaultClassNames().day} rounded-full !text-sm`,
        dropdown: `${getDefaultClassNames().dropdown} outline-none`,
        dropdowns: "gap-10 flex items-center outline-none",
        day_button: "w-full h-full p-0",
        today: "text-primary",
      }}
      {...props}
    />
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
