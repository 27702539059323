import AddCc from "./add-cc";
import Connections from "./connections/connections";
import SelectEmail from "./select-email";

const EmailSetup = () => {
  return (
    <div>
      <Connections />
      <div className="flex flex-wrap items-stretch gap-2">
        <SelectEmail />
        <AddCc />
      </div>
    </div>
  );
};

export default EmailSetup;
