import { useQuery } from "@tanstack/react-query";
import { getSheetData } from "@/api/user";
import PaginatedTable from "@/components/paginated-table/paginated-table";
import { useNavigate } from "react-router-dom";
import { RotateCw } from "lucide-react";

const PaymentReceipts = () => {
  const navigate = useNavigate();

  const {
    data,
    isLoading,
    isError,
  }: {
    data: any;
    isLoading: boolean;
    isError: any;
  } = useQuery({
    queryKey: ["Payment Receipts"],
    queryFn: async () => getSheetData("Payment Receipts"),
  });

  if (isLoading) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <RotateCw size={16} className="mx-auto animate-spin" />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        No data to show
      </div>
    );
  }

  return (
    <PaginatedTable
      data={data.map((invoice: any) => ({
        companyName: invoice?.clientID?.companyName,
        ...invoice,
        clientID: invoice?.clientID?.clientID,
      }))}
      sheetName="Payment Receipts"
      navigateUrl="/payment-receipts"
      buttonTitle="Add Payment Receipt"
      clickHandler={() => navigate("/payment-receipts/add")}
    />
  );
};

export default PaymentReceipts;
