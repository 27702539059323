import parse from "html-react-parser";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  FormField,
  Form,
  FormControl,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getEmailTemplates } from "@/api/email-templates";
import { EmailTemplate as EmailTemplateInterface } from "@/utils/constants";
import { RotateCcw } from "lucide-react";
import { replaceTemplatePlaceholders } from "@/utils/replace-template-placeholders";
import { sendTestMail } from "@/api/email-templates";
import { toast } from "sonner";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogTitle,
  AlertDialogTrigger,
  AlertDialogFooter,
  AlertDialogHeader,
} from "@/components/ui/alert-dialog";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { ScrollArea } from "@radix-ui/react-scroll-area";
import { ScrollBar } from "@/components/ui/scroll-area";

const EmailTemplate = ({ content }: { content: EmailTemplateInterface }) => {
  content.subject = replaceTemplatePlaceholders(content.subject, {
    businessName: "Business Name",
  });
  content.body = replaceTemplatePlaceholders(content.body, {
    salutation1: "Salutation1",
    salutation2: "Salutation2",
    salutation3: "Salutation3",
    invoiceNumber: "Invoice Number",
    invoiceAmount: "Invoice Amount",
    invoiceDueDate: "Invoice Due Date",
    invoiceDate: "Invoice Date",
    overdueDays: "Overdue Days",
    businessEmail: "Business Email",
    businessName: "Business Name",
    employeeName: "Employee Name",
    firstName: "First Name",
  });

  return (
    <div className="template template-body mt-8 md:ml-8 md:mt-0">
      <p className="h3 font-bold">Email Preview</p>
      <div className="mt-2 rounded-lg">
        <div className="flex gap-1">
          <p className="text-sm font-bold">Subject: </p>
          <p className="text-sm text-gray-700">{content.subject}</p>
        </div>
        <div className="mt-4 text-sm">
          <p className="mb-2 text-sm font-bold">Body: </p>
          <ScrollArea>
            <iframe
              srcDoc={content.body}
              title="Email Template Preview"
              className="h-[35rem] w-full"
            ></iframe>
            <ScrollBar orientation="vertical" />
          </ScrollArea>
        </div>
      </div>
    </div>
  );
};

const formSchema = z.object({
  template: z.string().min(1, {
    message: "Please select an email template.",
  }),
  to: z.string().email({ message: "Invalid email address." }),
});

const SelectEmail = () => {
  const [showDialog, setShowDialog] = useState(false);

  const {
    data,
    isFetching,
  }: {
    data: any;
    isFetching: boolean;
  } = useQuery({
    queryKey: ["emailTemplates"],
    queryFn: getEmailTemplates,
  });

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      template: "",
      to: "",
    },
  });

  const mutation = useMutation({
    mutationFn: sendTestMail,
    onSuccess: () => {
      toast.success("Test mail sent successfully");
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const handleSendMail = (e: any) => {
    e.preventDefault();
    form.trigger("to");
    if (form.getValues("to") === "") {
      return;
    }

    mutation.mutate({
      to: form.getValues("to"),
      type: form.getValues("template"),
    });
  };

  const { setValue } = form;
  if (isFetching) {
    return (
      <div className="flex items-center justify-center">
        <RotateCcw className="h-4 w-4 animate-spin" />
      </div>
    );
  }

  return (
    <div className="flex flex-col md:flex-row">
      <div className="h-fit rounded-md bg-white p-6 shadow-md md:w-1/2">
        <div>
          <h2 className="mb-4 text-lg font-semibold text-gray-800">
            Email Template
          </h2>
          <Form {...form}>
            <form>
              <FormField
                control={form.control}
                name="template"
                render={({ field }) => (
                  <FormItem>
                    <label>Email Template</label>
                    <FormControl>
                      <Select
                        {...field}
                        onValueChange={(e) => {
                          setValue("template", e);
                          form.trigger("template");
                        }}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Email Template">
                            {field.value}
                          </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                          {data?.map((template: EmailTemplateInterface) => (
                            <SelectItem
                              key={template.type}
                              value={template.type}
                            >
                              {template.type}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {form.getValues("template") && (
                <AlertDialog open={showDialog} onOpenChange={setShowDialog}>
                  <AlertDialogTrigger className="mt-5 flex w-full justify-end">
                    <Button
                      onClick={(e: any) => {
                        e.preventDefault();
                        setShowDialog(true);
                      }}
                    >
                      Send Test Mail
                    </Button>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>Enter Email address</AlertDialogTitle>
                      <AlertDialogDescription>
                        <FormField
                          control={form.control}
                          name="to"
                          render={({ field }) => (
                            <FormItem>
                              <FormControl>
                                <Input
                                  placeholder="example@example.com"
                                  {...field}
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>Cancel</AlertDialogCancel>
                      <AlertDialogAction
                        onClick={handleSendMail}
                        disabled={mutation.isPending}
                      >
                        Continue
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              )}
            </form>
          </Form>
        </div>
      </div>
      <div className="md:w-1/2">
        {form.getValues("template") && (
          <EmailTemplate
            content={data.find(
              (template: EmailTemplateInterface) =>
                template.type === form.getValues().template,
            )}
          />
        )}
      </div>
    </div>
  );
};

export default SelectEmail;
