import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormField,
  FormItem,
  FormControl,
  FormMessage,
} from "@/components/ui/form";
import { Checkbox } from "@/components/ui/checkbox";
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import useEditableState from "@/hooks/use-editable-state";
import DatePicker from "../ui/date-picker";
import PhoneInput from "react-phone-input-2";
import moment from "moment";

const formSchema = z.object({
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  dateOfBirth: z.date({ message: "Date of birth is required" }),
  dateOfJoining: z.date().optional(),
  department: z.string(),
  designation: z.string(),
  dateOfRelieving: z.date().optional(),
  birthdayReminder: z.string(),
  welcomeEmail: z.string(),
  relievingEmail: z.string(),
  email: z.string().email("Invalid email"),
  phoneNumber: z.string(),
});

type FormSchemaType = z.infer<typeof formSchema>;

const StaffDetailsForm = ({
  initialValues,
  isPending,
  onSubmit,
}: {
  initialValues?: FormSchemaType;
  isPending?: boolean;
  onSubmit: (values: any) => void;
}) => {
  const navigate = useNavigate();

  const [isEditable, setIsEditable] = useEditableState(initialValues);

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues
      ? {
          ...initialValues,
          dateOfBirth: new Date(initialValues.dateOfBirth),
          dateOfJoining: initialValues.dateOfJoining
            ? new Date(initialValues.dateOfJoining)
            : undefined,
          dateOfRelieving: initialValues.dateOfRelieving
            ? new Date(initialValues.dateOfRelieving)
            : undefined,
          welcomeEmail: "no",
          relievingEmail: "no",
        }
      : {
          firstName: "",
          lastName: "",
          dateOfBirth: undefined,
          dateOfJoining: undefined,
          department: "",
          designation: "",
          dateOfRelieving: undefined,
          birthdayReminder: "no",
          welcomeEmail: "no",
          relievingEmail: "no",
          email: "",
          phoneNumber: "+91",
        },
  });

  const { setValue } = form;

  return (
    <div className="container mx-auto rounded-md bg-white p-6 shadow-md">
      <div className="mb-5 flex flex-wrap items-center justify-between">
        <div className="mb-6 flex items-center gap-3">
          <ArrowLeft
            className="cursor-pointer rounded-full p-2 duration-150 hover:bg-gray-300"
            size={36}
            onClick={() => navigate(-1)}
          />
          <h2 className="text-2xl font-semibold">Staff Details Form</h2>
        </div>
        {!isEditable && (
          <Button
            className="w-full sm:w-[12rem]"
            onClick={() => {
              setIsEditable(true);
            }}
          >
            Edit Data
          </Button>
        )}
      </div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit((e) => {
            onSubmit({
              ...e,
              dateOfBirth: moment(e.dateOfBirth).format("YYYY-MM-DD"),
              dateOfJoining: e.dateOfJoining
                ? moment(e.dateOfJoining).format("YYYY-MM-DD")
                : null,
              dateOfRelieving: e.dateOfRelieving
                ? moment(e.dateOfRelieving).format("YYYY-MM-DD")
                : null,
            });
          })}
          className="space-y-6"
        >
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <label>First Name</label>
                  <FormControl>
                    <Input
                      disabled={!isEditable}
                      placeholder="First Name"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <label>Last Name</label>
                  <FormControl>
                    <Input
                      disabled={!isEditable}
                      placeholder="Last Name"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="phoneNumber"
              render={({ field }) => (
                <FormItem>
                  <label>Mobile Number</label>
                  <FormControl>
                    <PhoneInput
                      inputStyle={{
                        minWidth: "none",
                        width: "100%",
                      }}
                      value={field.value}
                      onChange={(e) => {
                        setValue("phoneNumber", e);
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="dateOfBirth"
              render={({ field }) => (
                <FormItem>
                  <label>Date of Birth</label>
                  <FormControl>
                    <DatePicker
                      disabled={!isEditable}
                      date={field.value ? new Date(field.value) : undefined}
                      onChange={(date) => {
                        field.onChange(date);
                      }}
                      placeholder="Date of Birth"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="dateOfJoining"
              render={({ field }) => (
                <FormItem>
                  <label>Date of Joining</label>
                  <FormControl>
                    <DatePicker
                      disabled={!isEditable}
                      date={field.value ? new Date(field.value) : undefined}
                      onChange={(date) => {
                        field.onChange(date);
                      }}
                      placeholder="-"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="department"
              render={({ field }) => (
                <FormItem>
                  <label>Department</label>
                  <FormControl>
                    <Input disabled={!isEditable} placeholder="-" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="designation"
              render={({ field }) => (
                <FormItem>
                  <label>Designation</label>
                  <FormControl>
                    <Input disabled={!isEditable} placeholder="-" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <label>Email</label>
                  <FormControl>
                    <Input
                      disabled={!isEditable}
                      placeholder="Email"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="dateOfRelieving"
              render={({ field }) => (
                <FormItem>
                  <label>Date of Relieving</label>
                  <FormControl>
                    <DatePicker
                      disabled={!isEditable}
                      date={field.value ? new Date(field.value) : undefined}
                      onChange={(date) => {
                        field.onChange(date);
                      }}
                      placeholder="-"
                    />
                    {/* <Input
                      disabled={!isEditable}
                      type="date"
                      {...field}
                      value={
                        field.value
                          ? new Date(field.value).toISOString().split("T")[0]
                          : ""
                      }
                    /> */}
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="birthdayReminder"
              render={({ field }) => (
                <FormItem className="mt-6 flex items-center gap-2">
                  <FormControl className="mt-2">
                    <Checkbox
                      disabled={!isEditable}
                      {...field}
                      value={field.value!.toString()}
                      onCheckedChange={(value) => {
                        field.onChange(value === true ? "yes" : "no");
                      }}
                      checked={field.value === "yes"}
                    />
                  </FormControl>
                  <label>Birthday Reminder</label>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div>
              <FormField
                control={form.control}
                name="welcomeEmail"
                render={({ field }) => (
                  <FormItem className="mt-6 flex items-center gap-2">
                    <FormControl className="mt-2">
                      <Checkbox
                        disabled={!isEditable}
                        {...field}
                        value={field.value!.toString()}
                        onCheckedChange={(value) => {
                          field.onChange(value === true ? "yes" : "no");
                        }}
                        checked={field.value === "yes"}
                      />
                    </FormControl>
                    <label>Welcome Email</label>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <p className="text-xs text-secondary">
                (Select only if staff member has joined the company)
              </p>
            </div>
            <div>
              <FormField
                control={form.control}
                name="relievingEmail"
                render={({ field }) => (
                  <FormItem className="mt-6 flex items-center gap-2">
                    <FormControl className="mt-2">
                      <Checkbox
                        disabled={!isEditable}
                        {...field}
                        value={field.value!.toString()}
                        onCheckedChange={(value) => {
                          field.onChange(value === true ? "yes" : "no");
                        }}
                        checked={field.value === "yes"}
                      />
                    </FormControl>
                    <label>Relieving Email</label>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <p className="text-xs text-secondary">
                (Select only if staff member is leaving the company)
              </p>
            </div>
          </div>
          {isEditable && (
            <div className="flex justify-end">
              <Button
                type="submit"
                className="w-full sm:w-[12rem]"
                disabled={isPending}
                loading={isPending}
              >
                {initialValues ? "Update" : "Create"} Staff Details
              </Button>
            </div>
          )}
        </form>
      </Form>
    </div>
  );
};

export default StaffDetailsForm;
