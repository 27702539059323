"use client";

import * as React from "react";
import { Label } from "@/components/ui/label";
import { TimePickerInput } from "./time-picker-input";

interface TimePickerDemoProps {
  date: Date | undefined;
  setDate: (date: Date | undefined) => void;
}

export default function TimePicker({ date, setDate }: TimePickerDemoProps) {
  const minuteRef = React.useRef<HTMLInputElement>(null);
  const hourRef = React.useRef<HTMLInputElement>(null);
  const amPmRef = React.useRef<HTMLSelectElement>(null);

  const handleAmPmChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (date) {
      const newDate = new Date(date);
      const isPm = e.target.value === "PM";
      const currentHours = date.getHours();
      if (isPm && currentHours < 12) {
        newDate.setHours(currentHours + 12);
      } else if (!isPm && currentHours >= 12) {
        newDate.setHours(currentHours - 12);
      }
      setDate(newDate);
    }
  };

  return (
    <div className="flex items-end gap-2">
      <div className="grid gap-1 text-center">
        <Label htmlFor="hours" className="text-xs">
          Hours
        </Label>
        <TimePickerInput
          picker="12hours"
          period={amPmRef.current?.value as "AM" | "PM"}
          date={date}
          setDate={setDate}
          ref={hourRef}
          max={12}
          onRightFocus={() => minuteRef.current?.focus()}
        />
      </div>
      <div className="grid gap-1 text-center">
        <Label htmlFor="minutes" className="text-xs">
          Minutes
        </Label>
        <TimePickerInput
          picker="minutes"
          date={date}
          setDate={setDate}
          ref={minuteRef}
          onLeftFocus={() => hourRef.current?.focus()}
          onRightFocus={() => amPmRef.current?.focus()}
        />
      </div>
      <div className="grid gap-1 text-center">
        <Label htmlFor="amPm" className="text-xs">
          AM/PM
        </Label>
        <select
          id="amPm"
          ref={amPmRef}
          className="w-16 rounded-md border border-input bg-background p-2 text-sm focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
          value={date!.getHours() >= 12 ? "PM" : "AM"}
          onChange={handleAmPmChange}
        >
          <option value="AM">AM</option>
          <option value="PM">PM</option>
        </select>
      </div>
    </div>
  );
}
