import { connectCustomMail, sendTestMail } from "@/api/user";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { UserContext } from "@/context/user-context";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";

const formSchema = z.object({
  host: z.string().min(1, { message: "Host is required." }),
  port: z.number().min(1, { message: "Port is required." }),
  user: z.string().min(1, { message: "User is required." }),
  password: z.string().min(1, { message: "Password is required." }),
  fromName: z.string().min(1, { message: "From Name is required." }),
  fromEmail: z.string().email({ message: "Invalid email address." }),
});

const CustomMailForm = () => {
  const { currentUser, setCurrentUser } = useContext(UserContext);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      host: currentUser?.customMail?.host || "",
      port: currentUser?.customMail?.port || 587,
      user: currentUser?.customMail?.user || "",
      password: currentUser?.customMail?.password || "",
      fromName: currentUser?.customMail?.fromName || "",
      fromEmail: currentUser?.customMail?.fromEmail || "",
      to: "",
    },
  });

  const connectMailMutation = useMutation({
    mutationFn: connectCustomMail,
    onSuccess: () => {
      toast.success("Mail configuration saved successfully.");
      setCurrentUser({
        ...currentUser!,
        customMail: form.getValues(),
      });
    },
    onError: (error: any) => {
      console.error(error);
    },
  });

  const sendTestMailMutation = useMutation({
    mutationFn: sendTestMail,
    onSuccess: () => {
      toast.success("Test mail sent successfully.");
    },
    onError: (error: any) => {
      toast.error("Failed to send test mail.");
    },
  });
  const handleSendTestMail = (e: any) => {
    e.preventDefault();
    if (!form.getValues().to) {
      toast.error("Invalid email address.");
      return;
    }
    sendTestMailMutation.mutate(form.getValues());
  };
  const onSubmit = (values: any) => {
    const { to, ...data } = values;
    connectMailMutation.mutate(data);
  };

  return (
    <div className="rounded-lg bg-white p-8 shadow-lg">
      <h2 className="mb-6 text-xl font-semibold text-gray-800">
        Email Configuration
      </h2>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            <FormField
              control={form.control}
              name="fromEmail"
              render={({ field }) => (
                <FormItem>
                  <label className="block text-sm font-medium text-gray-700">
                    From Email
                  </label>
                  <FormControl>
                    <Input
                      placeholder="example@example.com"
                      {...field}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="fromName"
              render={({ field }) => (
                <FormItem>
                  <label className="block text-sm font-medium text-gray-700">
                    From Name
                  </label>
                  <FormControl>
                    <Input
                      placeholder="John Doe"
                      {...field}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="host"
              render={({ field }) => (
                <FormItem>
                  <label className="block text-sm font-medium text-gray-700">
                    Host
                  </label>
                  <FormControl>
                    <Input
                      placeholder="smtp.example.com"
                      {...field}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="port"
              render={({ field }) => (
                <FormItem>
                  <label className="block text-sm font-medium text-gray-700">
                    Port
                  </label>
                  <FormControl>
                    <Input
                      placeholder="587"
                      {...field}
                      onChange={(e) => field.onChange(parseInt(e.target.value))}
                      type="number"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="user"
              render={({ field }) => (
                <FormItem>
                  <label className="block text-sm font-medium text-gray-700">
                    User
                  </label>
                  <FormControl>
                    <Input
                      placeholder="user"
                      {...field}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <label className="block text-sm font-medium text-gray-700">
                    Password
                  </label>
                  <FormControl>
                    <Input
                      type="password"
                      placeholder="password"
                      {...field}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="flex flex-col justify-end gap-5 sm:flex-row">
            <AlertDialog>
              <AlertDialogTrigger disabled={!form.formState.isValid}>
                <div
                  className={`flex h-full min-w-[10rem] items-center justify-center rounded-md bg-primary text-primary-foreground hover:bg-primary/90 ${
                    connectMailMutation.isPending ||
                    sendTestMailMutation.isPending
                      ? "pointer-events-none"
                      : ""
                  }`}
                >
                  Send Test Mail
                </div>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Enter Email address</AlertDialogTitle>
                  <AlertDialogDescription>
                    <FormField
                      control={form.control}
                      name="to"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Input
                              placeholder="example@example.com"
                              {...field}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction onClick={handleSendTestMail}>
                    Continue
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>

            <Button
              className="min-w-[10rem]"
              type="submit"
              disabled={
                connectMailMutation.isPending || sendTestMailMutation.isPending
              }
              loading={connectMailMutation.isPending}
            >
              Save Configuration
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default CustomMailForm;
