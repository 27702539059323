import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { SigninData } from "@/lib/constants/interface";
import { Link, useNavigate } from "react-router-dom";
import GoogleIcon from "@/assets/icons/google.svg";
import { useMutation } from "@tanstack/react-query";
import { forgotPassword, login } from "@/api/auth";
import { toast } from "sonner";
import { useState } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogTitle,
  AlertDialogTrigger,
  AlertDialogFooter,
  AlertDialogHeader,
} from "@/components/ui/alert-dialog";
import Logo from "@/assets/icons/logo.svg";

// Define the form schema using Zod
const formSchema = z.object({
  email: z.string().email({ message: "Invalid email address." }),
  password: z.string().min(1, { message: "Password is required." }),
});

const forgotPasswordSchema = z.object({
  forgotPasswordEmail: z.string().email({ message: "Invalid email address." }),
});

const ForgotPasswordDialog = ({
  showDialog,
  setShowDialog,
  form,
  handleSendVerificationMail,
  mutation,
}: {
  showDialog: boolean;
  setShowDialog: any;
  form: any;
  handleSendVerificationMail: any;
  mutation: any;
}) => {
  return (
    <AlertDialog open={showDialog} onOpenChange={setShowDialog}>
      <AlertDialogTrigger className="mt-5 flex w-full justify-end">
        <p
          className="text-xs hover:underline"
          onClick={(e: any) => {
            e.preventDefault();
            setShowDialog(true);
          }}
        >
          Forgot Password?
        </p>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Enter Email address</AlertDialogTitle>
          <AlertDialogDescription>
            <FormField
              control={form.control}
              name="forgotPasswordEmail"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder="example@example.com"
                      {...field}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            onClick={handleSendVerificationMail}
            disabled={mutation.isPending}
          >
            Send Verification Link
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const SignIn = () => {
  const navigate = useNavigate();

  const [showDialog, setShowDialog] = useState(false);

  const form = useForm({
    resolver: zodResolver(showDialog ? forgotPasswordSchema : formSchema),
    defaultValues: {
      email: "",
      password: "",
      forgotPasswordEmail: "",
    },
  });

  const mutation = useMutation({
    mutationFn: login,
    onSuccess: (res) => {
      navigate("/");
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const forgotPasswordMutation = useMutation({
    mutationFn: forgotPassword,
    onSuccess: (res) => {
      setShowDialog(false);
      toast.success("Verification link sent to your email.");
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  // Define a submit handler
  function onSubmit(values: SigninData) {
    showDialog
      ? forgotPasswordMutation.mutate(values.email)
      : mutation.mutate(values);
  }

  const handleSendVerificationMail = async (e: any) => {
    e.preventDefault();
    if (
      !(await form.trigger("forgotPasswordEmail")) ||
      form.getValues("forgotPasswordEmail") === ""
    ) {
      return;
    }

    forgotPasswordMutation.mutate(form.getValues("forgotPasswordEmail"));
  };

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-100">
      <div className="w-full max-w-md rounded-lg bg-white p-6 shadow-md">
        <img src={Logo} alt="Logo" className="mx-auto mb-6 w-24" />
        <h2 className="mb-6 text-center text-2xl font-bold">Sign In</h2>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <label>Email</label>
                  <FormControl>
                    <Input placeholder="example@example.com" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <label>Password</label>
                  <FormControl>
                    <Input type="password" placeholder="password" {...field} />
                  </FormControl>
                  <FormMessage />
                  <ForgotPasswordDialog
                    showDialog={showDialog}
                    setShowDialog={setShowDialog}
                    form={form}
                    handleSendVerificationMail={handleSendVerificationMail}
                    mutation={forgotPasswordMutation}
                  />
                </FormItem>
              )}
            />
            <Button
              type="submit"
              className="w-full"
              disabled={mutation.isPending}
              loading={mutation.isPending}
            >
              Sign In
            </Button>
          </form>
        </Form>
        <div className="mt-5">
          <a href={`${process.env.REACT_APP_BASE_URL}/auth/google`}>
            <Button
              variant={"outline"}
              className="flex w-full items-center justify-center gap-2"
              disabled={mutation.isPending}
            >
              <img
                src={GoogleIcon}
                alt="Google Icon"
                className="mr-2 h-6 w-6"
              />
              Continue with Google
            </Button>
          </a>
          <p className="mt-4 text-center">
            Don't have an account?{" "}
            <Link to="/sign-up" className="text-blue-500">
              Sign Up
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
