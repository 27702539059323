import axios from "axios";

const axiosClient = axios.create();

axiosClient.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axiosClient.defaults.withCredentials = true;

axiosClient.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (
      error.response.status === 401 &&
      window.location.pathname !== "/sign-in" &&
      window.location.pathname !== "/sign-up" &&
      window.location.pathname !== "/forgot-password" &&
      window.location.pathname !== "/reset-password"
    ) {
      window.location.href = "/sign-in";
    }

    return Promise.reject(error.response.data);
  },
);

export default axiosClient;
