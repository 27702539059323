import { disconnectCustomMail, disconnectGmail } from "@/api/user";
import { Button } from "@/components/ui/button";
import { UserContext } from "@/context/user-context";
import { useMutation } from "@tanstack/react-query";
import { useContext, useState } from "react";
import CustomMailForm from "./components/custom-mail-form";

const Connections = () => {
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const [showCustomMailForm, setShowCustomMailForm] = useState(false);

  const toggleShowCustomMailForm = () => {
    setShowCustomMailForm(!showCustomMailForm);
  };

  const disconnectGmailMutation = useMutation({
    mutationFn: disconnectGmail,
    onSuccess: () => {
      setCurrentUser({
        ...currentUser!,
        gmail: {
          accessToken: "",
          mail: "",
          refreshToken: "",
        },
      });
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const disconnectCustomMailMutation = useMutation({
    mutationFn: disconnectCustomMail,
    onSuccess: () => {
      setShowCustomMailForm(false);
      setCurrentUser({
        ...currentUser!,
        customMail: {
          fromEmail: "",
          password: "",
          fromName: "",
          host: "",
          port: 0,
          user: "", // Add the 'user' property here
        },
      });
    },
    onError: (error) => {
      console.error(error);
    },
  });

  return (
    <>
      <div className="flex flex-col gap-8 rounded-lg bg-gray-50 p-6 shadow-md">
        <div className="flex flex-col gap-6">
          <h2 className="text-xl font-semibold text-gray-800">
            Connect Your Accounts
          </h2>
          <div className="flex flex-col justify-between gap-5 lg:flex-row lg:items-center">
            <a
              className={`${
                currentUser?.gmail?.mail ? "pointer-events-none" : ""
              } w-[15rem] min-w-[15rem]`}
              href={`${process.env.REACT_APP_BASE_URL}/auth/gmail?id=${currentUser?._id}`}
            >
              <Button
                className={`w-full ${
                  currentUser?.gmail?.mail ? "bg-primary/50" : ""
                }`}
                disabled={disconnectGmailMutation.isPending}
              >
                {currentUser?.gmail?.mail ? "Gmail Connected" : "Connect Gmail"}
              </Button>
            </a>
            {currentUser?.gmail?.mail && (
              <div className="flex w-full flex-1 flex-col items-start justify-between gap-3 rounded-lg bg-white p-4 shadow-sm sm:flex-row sm:items-center">
                <p className="text-sm font-bold text-gray-700">
                  {currentUser?.gmail?.mail}
                </p>
                <Button
                  variant={"destructive"}
                  onClick={() => disconnectGmailMutation.mutate()}
                  disabled={disconnectGmailMutation.isPending}
                  loading={disconnectGmailMutation.isPending}
                >
                  Disconnect
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col justify-between gap-5 lg:flex-row lg:items-center">
          <div className={`w-[15rem] min-w-[15rem]`}>
            <Button className={`w-full`} onClick={toggleShowCustomMailForm}>
              Custom Mail Configuration
            </Button>
          </div>
          {currentUser?.customMail?.fromEmail && (
            <div className="flex w-full flex-1 flex-col items-start justify-between gap-3 rounded-lg bg-white p-4 shadow-sm sm:flex-row sm:items-center">
              <p className="text-sm font-bold text-gray-700">
                {currentUser?.customMail?.fromEmail}
              </p>
              <Button
                variant={"destructive"}
                onClick={() => disconnectCustomMailMutation.mutate()}
                disabled={disconnectCustomMailMutation.isPending}
                loading={disconnectCustomMailMutation.isPending}
              >
                Disconnect
              </Button>
            </div>
          )}
        </div>
        {showCustomMailForm && <CustomMailForm />}
      </div>
    </>
  );
};

export default Connections;
