"use client";

import { Pie, PieChart } from "recharts";

import { Card, CardContent } from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";

const colors = [
  "rgba(40, 183, 140, 1)",
  "rgba(40, 183, 140, 0.8)",
  "rgba(40, 183, 140, 0.6)",
  "rgba(40, 183, 140, 0.4)",
  "rgba(40, 183, 140, 0.2)",
];

const CustomerWiseRevenueChart = ({ data }: { data: any }) => {
  console.log(data);
  const generateChartConfig = (
    data: Array<{ totalRevenue: number; clientName: string }>,
  ) => {
    return data.reduce(
      (config, item, index) => {
        config[item.clientName] = {
          label: item.clientName?.toLowerCase(),
          color: colors[index % colors.length],
        };

        return config;
      },
      {} as { [key: string]: { label: string; color: string } },
    );
  };

  const chartConfig = generateChartConfig(data) satisfies ChartConfig;

  data = data.map((item: any, index: number) => ({
    ...item,
    // fill: `var(--color-${item.clientName?.toLowerCase()})`,
    // fill: colors[index],
    fill: colors[index % colors.length],
  }));

  console.log("data", data);

  return (
    <div className="flex h-[21.5rem] flex-col gap-5 rounded-xl border p-5">
      <p className="text-center">Customer wise Revenue</p>
      {data.length > 0 ? (
        <Card className="flex flex-col border-0">
          <CardContent className="flex-1 pb-0">
            <ChartContainer
              config={chartConfig}
              className="mx-auto aspect-square max-h-[250px]"
            >
              <PieChart>
                <ChartTooltip
                  cursor={false}
                  content={<ChartTooltipContent hideLabel />}
                />
                <Pie
                  data={data}
                  dataKey="totalRevenue"
                  nameKey="clientName"
                  outerRadius={80}
                  innerRadius={60}
                />
              </PieChart>
            </ChartContainer>
          </CardContent>
        </Card>
      ) : (
        <>
          <p className="text-center font-semibold">No data available</p>
        </>
      )}
    </div>
  );
};

export default CustomerWiseRevenueChart;
