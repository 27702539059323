"use client";

import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useState } from "react";

export default function DatePicker({
  disabled = false,
  placeholder,
  date,
  onChange,
}: {
  disabled?: boolean;
  placeholder?: string;
  date: Date | undefined;
  onChange: (date: Date | null) => void;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button
          disabled={disabled}
          onClick={() => setIsOpen(!isOpen)}
          variant={"outline"}
          className={cn(
            "flex w-full justify-between font-normal",
            !date && "text-muted-foreground",
          )}
        >
          {date ? format(date, "dd/MM/yyyy") : <span>{placeholder}</span>}
          <CalendarIcon className="mr-2 h-4 w-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onDayClick={(day) => {
            setIsOpen(false);
            onChange(day);
          }}
          setIsOpen={setIsOpen}
        />
      </PopoverContent>
    </Popover>
  );
}
