import { UserContext } from "@/context/user-context";
import { useContext, useEffect } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  FormField,
  Form,
  FormControl,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { EmailReminderContext } from "@/context/email-reminder-context";

const formSchema = z.object({
  email: z.string().email({ message: "Invalid email address." }),
});

const SelectEmail = () => {
  const { updateReminderMutation, reminderConfiguration } =
    useContext(EmailReminderContext);
  const { currentUser } = useContext(UserContext);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email:
        reminderConfiguration?.businessEmailType === "gmail"
          ? currentUser?.gmail?.mail!
          : currentUser?.customMail?.fromEmail!,
    },
  });

  const { setValue } = form;

  form.watch("email");

  const onSubmit = (values: { email: string }) => {
    updateReminderMutation.mutate({
      businessEmailType:
        values.email === currentUser?.gmail?.mail ? "gmail" : "custom",
    });
  };

  useEffect(() => {
    form.setValue(
      "email",
      reminderConfiguration?.businessEmailType === "gmail"
        ? currentUser?.gmail?.mail!
        : currentUser?.customMail?.fromEmail!,
    );
  }, [currentUser, reminderConfiguration]);

  return (
    <>
      <div className="mt-8 w-full max-w-lg rounded-lg bg-white p-6 shadow-lg">
        <h2 className="mb-4 text-lg font-semibold text-gray-800">
          Select email to send reminders
        </h2>

        {currentUser?.gmail?.mail || currentUser?.customMail?.fromEmail ? (
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Select
                        {...field}
                        onValueChange={(e) => setValue("email", e)}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Select email id from which emails will be sent" />
                        </SelectTrigger>
                        <SelectContent>
                          {currentUser?.gmail?.mail && (
                            <SelectItem value={currentUser.gmail.mail}>
                              {currentUser.gmail.mail}
                            </SelectItem>
                          )}
                          {currentUser?.customMail?.fromEmail && (
                            <SelectItem
                              value={currentUser.customMail.fromEmail}
                            >
                              {currentUser.customMail.fromEmail}
                            </SelectItem>
                          )}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex justify-end">
                <Button
                  type="submit"
                  className="mt-6 w-fit min-w-[10rem]"
                  disabled={updateReminderMutation.isPending}
                  loading={updateReminderMutation.isPending}
                >
                  Save Configuration
                </Button>
              </div>
            </form>
          </Form>
        ) : (
          <p className="text-gray-500">
            Please configure email settings first.
          </p>
        )}
      </div>
    </>
  );
};

export default SelectEmail;
