import React from "react";
import { AlertDialog, AlertDialogContent } from "../ui/alert-dialog";
import { cn } from "@/lib/utils";
import { Button, buttonVariants } from "../ui/button";
import { Input } from "../ui/input";
import { Download, Upload } from "lucide-react";

const ImportFileModal = ({
  open,
  file,
  tableType,
  setOpen,
  onConfirm,
  onChange,
}: {
  open: boolean;
  file: File | null;
  tableType: "Client Database" | "Staff details" | "Payment Reminders";
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent>
        <a
          className={`${cn(buttonVariants({ variant: "default" }))} flex w-full items-center justify-center gap-1 text-white`}
          href={
            tableType === "Client Database"
              ? "/client-database.xlsx"
              : tableType === "Payment Reminders"
                ? "/invoice.xlsx"
                : "/staff-details.xlsx"
          }
          download
        >
          <Download size={16} />
          Download Template
        </a>

        <label
          htmlFor="excel-upload"
          className={`${cn(buttonVariants({ variant: "outline" }))} flex w-full items-center justify-center gap-2`}
        >
          <Upload size={16} />
          {file ? file.name : "Upload File"}
        </label>
        <Input
          type="file"
          accept=".xlsx"
          id="excel-upload"
          className="hidden w-[12.5rem]"
          onChange={onChange}
        />
        <div className="flex items-center justify-end gap-2">
          <Button variant={"outline"} onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button onClick={onConfirm}>Import</Button>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ImportFileModal;
