import { useQuery } from "@tanstack/react-query";
import { getSheetData } from "@/api/user";
import PaginatedTable from "@/components/paginated-table/paginated-table";
import { useNavigate } from "react-router-dom";
import { RotateCw } from "lucide-react";

const StaffDetails = () => {
  const navigate = useNavigate();

  const {
    data,
    isLoading,
    isError,
  }: {
    data: any;
    isLoading: boolean;
    isError: any;
  } = useQuery({
    queryKey: ["Staff details"],
    queryFn: async () => getSheetData("Staff details"),
  });

  if (isLoading) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <RotateCw size={16} className="mx-auto animate-spin" />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        No data to show
      </div>
    );
  }

  return (
    <PaginatedTable
      data={data}
      sheetName="Staff details"
      navigateUrl="/staff-details"
      buttonTitle="Add Staff"
      clickHandler={() => navigate("/staff-details/add")}
    />
  );
};

export default StaffDetails;
