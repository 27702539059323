import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Input } from "@/components/ui/input";
import { Button, buttonVariants } from "@/components/ui/button";
import {
  Form,
  FormField,
  FormItem,
  FormControl,
  FormMessage,
} from "@/components/ui/form";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { ArrowLeft, Upload } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import useEditableState from "@/hooks/use-editable-state";
import { useContext } from "react";
import { UserContext } from "@/context/user-context";
import PaginatedTable from "../paginated-table/paginated-table";
import Combobox from "../ui/combo-box";
import DatePicker from "../ui/date-picker";
import moment from "moment";
import { cn } from "@/lib/utils";

const formSchema = z
  .object({
    clientID: z.string().min(1, "Client ID is required"),
    invoiceNumber: z.string().min(1, "Invoice number is required"),
    invoiceDate: z.date({ message: "Invoice date is required" }),
    dueDate: z.date({ message: "Due date is required" }),
    paymentStatus: z.string().min(1, "Payment status is required"),
    invoiceAmount: z.number().min(1, "Invoice amount is required"),
    amountPaid: z.number(),
    attachment: z.custom<File>(),
    payments: z
      .array(
        z.object({
          paymentDate: z.string().min(1, "Payment date is required"),
          amountPaid: z.number().min(1, "Amount paid is required"),
        }),
      )
      .optional(),
    reminder: z.string(),
  })
  .refine((data) => data.invoiceDate <= data.dueDate, {
    message: "Due date cannot be before invoice date",
    path: ["dueDate"], // path to show the error message
  });

const InvoiceForm = ({
  clientIDs,
  initialValues,
  invoiceId,
  isPending,
  onSubmit,
}: {
  clientIDs: any;
  initialValues?: {
    clientID: string;
    invoiceNumber: string;
    invoiceDate: Date;
    dueDate: Date;
    paymentStatus: string;
    amountPaid: number;
    invoiceAmount: number;
    attachment: string;
    reminder: string;
  };
  invoiceId?: string;
  isPending?: boolean;
  onSubmit: (data: any) => void;
}) => {
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);

  const [isEditable, setIsEditable] = useEditableState(initialValues);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues
      ? {
          ...initialValues,
          clientID: clientIDs.find(
            (client: any) => client._id === initialValues.clientID,
          )?.clientID,
          dueDate: new Date(initialValues!.dueDate),
          invoiceDate: new Date(initialValues!.invoiceDate),
        }
      : {
          clientID: "",
          invoiceNumber: "",
          invoiceDate: undefined,
          dueDate: undefined,
          paymentStatus: "",
          amountPaid: 0,
          payments: [],
          attachment: "",
          reminder: "on",
        },
  });

  const { setValue } = form;

  return (
    <>
      <div className="mx-auto rounded-md bg-white p-6 shadow-md">
        <div className="mb-5 flex flex-wrap items-center justify-between">
          <div className="mb-6 flex items-center gap-3">
            <ArrowLeft
              className="cursor-pointer rounded-full p-2 duration-150 hover:bg-gray-300"
              size={36}
              onClick={() => navigate(-1)}
            />
            <h2 className="text-2xl font-semibold">Invoice Form</h2>
          </div>
          {!isEditable && (
            <Button
              className="w-full sm:w-[12rem]"
              onClick={() => {
                setIsEditable(true);
              }}
            >
              Edit Data
            </Button>
          )}
        </div>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit((e) => {
              onSubmit({
                ...e,
                clientID: clientIDs.find(
                  (client: any) => client.clientID === e.clientID,
                )?._id,
                dueDate: moment(e.dueDate).format("YYYY-MM-DD"),
                invoiceDate: moment(e.invoiceDate).format("YYYY-MM-DD"),
              });
            })}
            className="space-y-6"
          >
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
              <FormField
                control={form.control}
                name="clientID"
                render={({ field }) => (
                  <FormItem>
                    <label>Client ID</label>
                    <FormControl>
                      <Combobox
                        disabled={!isEditable}
                        placeholder="Client ID"
                        value={form.getValues("clientID")}
                        data={clientIDs.map((client: any) => {
                          return {
                            value: client.clientID,
                            label: `${client.companyName}  (${client.clientID})`,
                          };
                        })}
                        onChange={(value: string) => {
                          setValue("clientID", value);
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="invoiceNumber"
                render={({ field }) => (
                  <FormItem>
                    <label>Invoice Number</label>
                    <FormControl>
                      <Input
                        disabled={!isEditable}
                        placeholder="Invoice Number"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="invoiceDate"
                render={({ field }) => (
                  <FormItem>
                    <label>Invoice Date</label>
                    <FormControl>
                      <DatePicker
                        disabled={!isEditable}
                        date={field.value ? new Date(field.value) : undefined}
                        onChange={(date) => {
                          console.log(date);
                          field.onChange(date);
                        }}
                        placeholder="Invoice Date"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="dueDate"
                render={({ field }) => (
                  <FormItem>
                    <label>Due Date</label>
                    <FormControl>
                      <DatePicker
                        disabled={!isEditable}
                        date={field.value ? new Date(field.value) : undefined}
                        onChange={(date) => {
                          field.onChange(date);
                        }}
                        placeholder="Due Date"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="invoiceAmount"
                render={({ field }) => (
                  <FormItem>
                    <label>Invoice Amount</label>
                    <FormControl>
                      <Input
                        disabled={!isEditable}
                        placeholder="Invoice Amount"
                        type="number"
                        {...field}
                        onChange={(e) => {
                          // allow maximum of 2 decimal places
                          const value = e.target.value;
                          const regex = /^[0-9]+(\.[0-9]{0,2})?$/;
                          if (regex.test(value)) {
                            field.onChange(parseFloat(value));
                          }
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {initialValues && (
                <FormField
                  control={form.control}
                  name="amountPaid"
                  render={({ field }) => (
                    <FormItem>
                      <label>Amount Paid</label>
                      <FormControl>
                        <Input
                          disabled
                          placeholder="AmountPaid"
                          type="number"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
              <FormField
                control={form.control}
                name="paymentStatus"
                render={({ field }) => (
                  <FormItem>
                    <label>Payment Status</label>
                    <FormControl>
                      <Select
                        disabled={!isEditable}
                        {...field}
                        onValueChange={(e) => setValue("paymentStatus", e)}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Select Payment Status" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="pending">Pending</SelectItem>
                          <SelectItem value="paid">Paid</SelectItem>
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="attachment"
                render={({ field }) => (
                  <FormItem className="relative">
                    <label>Attachment</label>
                    {!currentUser?.gmail || !currentUser?.gmail?.mail ? (
                      <p className="text-xs text-secondary">
                        Please connect your Gmail account to add attachments
                      </p>
                    ) : (
                      <FormControl className="relative overflow-hidden">
                        <>
                          {!isEditable && !field.value && (
                            <p className="pt-4 text-xs">No attachment</p>
                          )}
                          {typeof field.value === "string" && (
                            <Link
                              to={field.value}
                              target="_blank"
                              className={`absolute left-0 top-8 max-w-[200px] truncate text-sm text-secondary ${
                                isEditable && "pointer-events-none"
                              }`}
                            >
                              {field.value}
                            </Link>
                          )}
                          {isEditable && (
                            <>
                              {" "}
                              <label
                                htmlFor="attachment"
                                className={`${cn(buttonVariants({ variant: "outline" }))} flex w-full items-center justify-center gap-2`}
                              >
                                <Upload size={16} />

                                {field.value
                                  ? (field.value as unknown as File).name
                                  : "Upload File"}
                              </label>
                              <Input
                                className={`hidden ${typeof field.value === "string" && field.value ? "!opacity-0" : "!opacity-100"}`}
                                type="file"
                                id="attachment"
                                disabled={!isEditable}
                                name="attachment"
                                onChange={(e) => {
                                  const file = e.target.files?.[0];
                                  if (file) {
                                    field.onChange(file); // Pass the file to your form control
                                  }
                                }}
                                placeholder="Attachment"
                              />
                            </>
                          )}
                        </>
                      </FormControl>
                    )}
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="reminder"
                render={({ field }) => (
                  <FormItem className="mt-6 flex items-center gap-2">
                    <FormControl className="mt-2">
                      <Checkbox
                        disabled={!isEditable}
                        {...field}
                        value={field.value.toString()}
                        checked={field.value.toLowerCase() === "on"}
                        onCheckedChange={(value) => {
                          field.onChange(value === true ? "on" : "off");
                        }}
                      />
                    </FormControl>
                    <label>Reminder</label>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            {isEditable && (
              <div className="flex justify-end">
                <Button
                  className="sm:w-[14rem]"
                  type="submit"
                  disabled={isPending}
                  loading={isPending}
                >
                  {initialValues ? "Update" : "Add"} Payment Reminder
                </Button>
              </div>
            )}
          </form>
        </Form>
      </div>
      {initialValues && (
        <div className="mx-auto mt-5 rounded-md bg-white p-6 shadow-md">
          <h2 className="text-2xl font-semibold">Payment Receipts</h2>
          <PaginatedTable
            showSearch={false}
            invoiceId={invoiceId}
            navigateUrl={`/payment-receipts/${invoiceId}/edit`}
            buttonTitle="Add Receipt"
            sheetName="Payment Receipts"
            clickHandler={() => {
              navigate(`/payment-receipts/${invoiceId}/add`);
            }}
            data={form.getValues("payments")}
          />
        </div>
      )}
    </>
  );
};

export default InvoiceForm;
