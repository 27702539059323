import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "@/components/sidebar/sidebar";
import { useContext, useEffect } from "react";
import { UserContext } from "@/context/user-context";
import { getUser } from "@/api/user";
import { useQuery } from "@tanstack/react-query";
import { Button } from "@/components/ui/button";
import moment from "moment";
import { EmailReminderContext } from "@/context/email-reminder-context";

const MainLayout = () => {
  const allowedRoutes = ["/settings", "/reminder-configuration", "/help"];
  const navigate = useNavigate();
  const location = useLocation();

  const { currentUser, setCurrentUser } = useContext(UserContext);
  const { reminderConfiguration } = useContext(EmailReminderContext);

  const { data, isPending, error } = useQuery({
    queryKey: ["access_token"],
    queryFn: getUser,
    enabled: true,
  });

  useEffect(() => {
    if (data) {
      // @ts-ignore
      setCurrentUser(data);
    }
  }, [data]);

  useEffect(() => {
    if (currentUser) {
      if (!currentUser.isVerified) {
        navigate("/settings");
      } else if (
        (!reminderConfiguration?.businessDetails?.businessName ||
          !reminderConfiguration.businessDetails?.email ||
          !reminderConfiguration.businessDetails?.mobileNumber ||
          !currentUser?.gmail?.mail) &&
        !allowedRoutes.includes(location.pathname)
      ) {
        navigate("/reminder-configuration");
      }
    }
  }, [currentUser, location]);

  if (isPending) return null;
  if (error) {
    setCurrentUser(null);
    navigate("/sign-in");
  }

  return (
    <div className="flex h-screen w-screen flex-col">
      {currentUser &&
        (!currentUser.isVerified ||
          currentUser?.isTrial ||
          currentUser.subscription?.status === "expired") && (
          <div
            className={`relative flex items-center justify-center gap-2 bg-white p-2 text-center text-[#404040] shadow-md sm:left-[18.125rem] sm:w-[calc(100vw-18.125rem)]`}
          >
            {currentUser.isVerified ? (
              <>
                {currentUser.isTrial ? (
                  <>
                    Your trial will expire in{" "}
                    {moment(currentUser.createdAt).add(7, "days").fromNow()}.
                    <Button variant={"destructive"}>
                      <Link
                        to={
                          "https://billing.finbite.in/checkout/66b855e39a70b5449741de12"
                        }
                      >
                        Upgrade plan
                      </Link>
                    </Button>
                  </>
                ) : currentUser.subscription?.status === "expired" ? (
                  <>
                    Your subscription has expired.
                    <Button
                      variant={
                        currentUser.subscription?.status === "expired"
                          ? "destructive"
                          : "default"
                      }
                    >
                      <Link
                        to={
                          "https://billing.finbite.in/checkout/66b855e39a70b5449741de12"
                        }
                      >
                        Renew Plan
                      </Link>
                    </Button>
                  </>
                ) : null}
              </>
            ) : (
              <p className="font-semibold text-destructive">
                Your email is not verified. Please verify your email.
              </p>
            )}
          </div>
        )}
      <div className="flex flex-1 items-stretch">
        <Sidebar />
        <div
          className={
            "relative w-full overflow-y-auto px-7 pt-16 sm:left-[18.125rem] sm:w-[calc(100vw-18.125rem)]"
          }
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
