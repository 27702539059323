import { sendVerificationLink } from "@/api/auth";
import { deleteUser, updateUser } from "@/api/user";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { UserContext } from "@/context/user-context";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { CheckCircle, XCircle } from "lucide-react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";
import ConfirmDeleteAccountModal from "./confirm-delete-account-modal";

const formSchema = z.object({
  username: z
    .string()
    .min(3, { message: "Name must be at least 3 characters" }),
  email: z.string().email(),
});

const ProfileTab = () => {
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const form = useForm({
    defaultValues: {
      username: currentUser?.username,
      email: currentUser?.email,
    },
    resolver: zodResolver(formSchema),
  });

  const sendVerificationLinkMutation = useMutation({
    mutationFn: sendVerificationLink,
    onSuccess: () => {
      toast.success("Verification link sent to your email");
    },
    onError: (err: any) => {
      toast.error(err.message);
    },
  });

  const handleSendVerifyLink = () => {
    sendVerificationLinkMutation.mutate(currentUser?.email || "");
  };

  const updateUserMutation = useMutation({
    mutationFn: updateUser,
    onSuccess: () => {
      setCurrentUser({
        ...currentUser!,
        username: form.getValues("username") || "",
      });
      toast.success("Profile updated successfully");
    },
    onError: (err: any) => {
      toast.error(err.message);
    },
  });

  const deleteAccontMutation = useMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      setCurrentUser(null);
      window.location.reload();
    },
    onError: (err: any) => {
      toast.error(err.message);
    },
  });

  const handleDeleteAccount = () => {
    deleteAccontMutation.mutate();
  };

  const onSubmit = (data: any) => {
    updateUserMutation.mutate(data);
  };

  return (
    <div className="mx-auto flex flex-col items-center justify-center gap-10 sm:w-1/2">
      <div className="w-full rounded-md bg-white p-6 shadow-md">
        <ConfirmDeleteAccountModal
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
          onConfirm={handleDeleteAccount}
        />

        <p className="mb-6 text-lg font-semibold">Profile Details</p>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="username"
              render={({ field }) => (
                <FormItem>
                  <label>Full Name</label>
                  <FormControl>
                    <Input placeholder="Full Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <div className="flex w-full items-center justify-between">
                    <label>Email</label>

                    {currentUser?.isVerified ? (
                      <div className="flex items-center gap-1 text-green-500">
                        <CheckCircle size={14} />
                        <p className="text-sm">Verified</p>
                      </div>
                    ) : (
                      <div className="flex items-center gap-1 text-red-500">
                        <p
                          className="mr-2 cursor-pointer text-sm text-green-500 underline"
                          onClick={handleSendVerifyLink}
                        >
                          Verify now
                        </p>
                        <XCircle size={14} />
                        <p className="text-sm">Verified</p>
                      </div>
                    )}
                  </div>
                  <FormControl>
                    <Input disabled placeholder="Email" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex items-center justify-between">
              <Button
                type="submit"
                disabled={
                  updateUserMutation.isPending || deleteAccontMutation.isPending
                }
                loading={updateUserMutation.isPending}
                className="w-40"
              >
                Update Profile
              </Button>
            </div>
          </form>
        </Form>
      </div>
      <Button
        variant={"destructive"}
        disabled={
          deleteAccontMutation.isPending || updateUserMutation.isPending
        }
        loading={deleteAccontMutation.isPending}
        className="w-40"
        onClick={(e) => {
          e.preventDefault();
          setDeleteModalOpen(true);
        }}
      >
        Delete Account
      </Button>
    </div>
  );
};

export default ProfileTab;
