import { addDataToSheet } from "@/api/user";
import PaymentReceiptForm from "@/components/forms/payment-receipt-form";
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

const AddPaymentReceipt = () => {
  const navigate = useNavigate();
  const { invoiceId } = useParams();

  const mutation = useMutation({
    mutationFn: addDataToSheet,
    onSuccess: () => {
      navigate(`/payment-reminders/${invoiceId}`);
      toast.success("Payment receipt added successfully");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const onSubmit = (values: any) => {
    mutation.mutate({
      data: {
        ...values,
        invoiceID: invoiceId,
      },
      sheetName: "Payment Receipts",
    });
  };

  return (
    <PaymentReceiptForm onSubmit={onSubmit} isPending={mutation.isPending} />
  );
};

export default AddPaymentReceipt;
