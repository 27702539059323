import { useState, ChangeEvent, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../ui/alert-dialog";
import {
  InvalidateQueryFilters,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { deleteSheetRows, uploadSheetData } from "@/api/user";
import { camelCaseToSpaces, isValidDate } from "@/utils/string-helpers";
import moment from "moment";
import { Eye, PenBox, Plus } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { ScrollArea, ScrollBar } from "../ui/scroll-area";
import ImportFileModal from "./import-file-modal";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";

const PaginatedTable = ({
  showSearch = true,
  data,
  navigateUrl,
  buttonTitle,
  sheetName,
  invoiceId,
  clickHandler,
}: {
  showSearch?: boolean;
  data: any;
  buttonTitle?: string;
  sheetName: string;
  navigateUrl: string;
  invoiceId?: string;
  clickHandler: () => void;
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage] = useState(50);
  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [sortField, setSortField] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [file, setFile] = useState<File | null>(null);
  const [showImportModal, setShowImportModal] = useState(false);

  const mutation = useMutation({
    mutationFn: deleteSheetRows,
    onSuccess: () => {
      queryClient.invalidateQueries([sheetName] as InvalidateQueryFilters);
      setSelectedRows(new Set());
      setSelectAll(false);
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const handleDeleteRows = () => {
    mutation.mutate({
      sheetName,
      rows: Array.from(selectedRows),
      invoiceID: invoiceId,
    });
  };

  // Handle sorting when a header is clicked
  const handleSort = (field: string) => {
    if (sortField === field) {
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  // Filter data based on search query
  let filteredData = searchQuery
    ? data.filter((row: any) =>
        Object.values(row).some((value: any) =>
          value.toString().toLowerCase().includes(searchQuery.toLowerCase()),
        ),
      )
    : data;

  if (sortField) {
    filteredData = filteredData.sort((a: any, b: any) => {
      if (
        String(a[sortField])?.toLocaleLowerCase() <
        String(b[sortField])?.toLocaleLowerCase()
      )
        return sortOrder === "asc" ? -1 : 1;
      if (
        String(a[sortField])?.toLocaleLowerCase() >
        String(b[sortField])?.toLocaleLowerCase()
      )
        return sortOrder === "asc" ? 1 : -1;
      return 0;
    });
  }

  const startIndex = (currentPage - 1) * rowsPerPage;
  const currentData = filteredData
    ? filteredData.slice(startIndex, startIndex + rowsPerPage)
    : [];

  const totalPages = filteredData
    ? Math.ceil((filteredData.length - 1) / rowsPerPage) + 1
    : 1;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleRowCheckboxChange =
    (rowId: string) => (e: ChangeEvent<HTMLInputElement>) => {
      const isChecked = e.target.checked;
      setSelectedRows((prev) => {
        const newSelection = new Set(prev);
        if (isChecked) {
          newSelection.add(rowId);
        } else {
          newSelection.delete(rowId);
        }
        return newSelection;
      });
    };

  const handleSelectAllChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const allRowIndices = new Set<string>();
      filteredData.forEach((data: any) => allRowIndices.add(data._id));
      setSelectedRows(allRowIndices);
    } else {
      setSelectedRows(new Set());
    }
    setSelectAll(isChecked);
  };

  const isRowSelected = (rowId: string) => selectedRows.has(rowId);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page on new search
  };

  const header =
    data.length > 0
      ? Object.keys(data[0])
          .map((key) => ({
            header: camelCaseToSpaces(key),
            accessor: key,
          }))
          .filter((header) => header.accessor !== "_id")
      : [];

  useEffect(() => {
    if (selectedRows.size === filteredData.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedRows, filteredData]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setFile(files[0]);
    }
  };

  const fileUploadMutation = useMutation({
    mutationFn: uploadSheetData,
    onSuccess: () => {
      queryClient.invalidateQueries([sheetName] as InvalidateQueryFilters);
      setFile(null);
    },
    onError: (error) => {
      setFile(null);
      toast.error(error.message);
    },
  });

  const handleUploadFile = () => {
    if (file) {
      setShowImportModal(false);
      fileUploadMutation.mutate({
        file,
        sheetName,
      });
    }
  };

  // useEffect(() => {
  //   if (file) {
  //     fileUploadMutation.mutate({
  //       file,
  //       sheetName,
  //     });
  //   }
  // }, [file]);

  return (
    <TooltipProvider>
      <div className="p-4">
        <ImportFileModal
          open={showImportModal}
          tableType={sheetName as any}
          setOpen={setShowImportModal}
          onConfirm={handleUploadFile}
          onChange={handleFileChange}
          file={file}
        />
        <div className="mb-4 flex flex-col justify-between gap-5 lg:flex-row lg:items-center">
          <Input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchInputChange}
            className={`w-full rounded-lg border border-gray-300 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 lg:w-[20rem] ${
              showSearch ? "opacity-100" : "pointer-events-none opacity-0"
            }`}
          />
          <div className="flex flex-col gap-3 lg:flex-row lg:items-center">
            {sheetName !== "Payment Receipts" && (
              <Button
                onClick={() => setShowImportModal(true)}
                className="w-full lg:w-[12rem]"
                disabled={fileUploadMutation.isPending}
                loading={fileUploadMutation.isPending}
              >
                Import Excel File
              </Button>
            )}
            <Button onClick={clickHandler} className="w-full lg:w-[12rem]">
              {buttonTitle}
            </Button>
            {selectedRows.size > 0 && (
              <AlertDialog>
                <AlertDialogTrigger>
                  <Button
                    className="w-full bg-red-500 text-white hover:bg-red-600 lg:w-[12rem]"
                    variant={"destructive"}
                  >
                    Delete
                  </Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                    <AlertDialogDescription>
                      <p>Are you sure you want to delete these rows?</p>
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel className="text-gray-600">
                      Cancel
                    </AlertDialogCancel>
                    <AlertDialogAction
                      onClick={handleDeleteRows}
                      className="bg-red-500 text-white hover:bg-red-600"
                    >
                      Continue
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            )}
          </div>
        </div>

        {data.length > 0 ? (
          <div className="flex flex-col gap-5">
            <ScrollArea className="max-h-[70vh] rounded-lg border border-gray-300 shadow-md">
              <Table className="min-w-full whitespace-nowrap">
                <TableHeader>
                  <TableRow className="bg-gray-200 text-gray-600 hover:bg-gray-200">
                    <TableHead className="p-2">
                      <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAllChange}
                        className="form-checkbox"
                      />
                    </TableHead>
                    {header.map(
                      (head: { header: string; accessor: string }) => (
                        <TableHead
                          key={head.accessor}
                          className="cursor-pointer p-2 font-semibold"
                          onClick={() => handleSort(head.accessor)}
                        >
                          {head.header}
                          {sortField === head.accessor && (
                            <span>{sortOrder === "asc" ? " ↑" : " ↓"}</span>
                          )}
                        </TableHead>
                      ),
                    )}
                    {sheetName !== "Payment Receipts" && (
                      <TableHead className="p-2 font-semibold">
                        Actions
                      </TableHead>
                    )}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {currentData.map((row: any, rowIndex: number) => (
                    <TableRow
                      key={rowIndex}
                      className={`border-b ${isRowSelected(row._id) ? "bg-gray-100" : "bg-white"}`}
                    >
                      <TableCell className="p-2">
                        <input
                          type="checkbox"
                          checked={isRowSelected(row._id)}
                          onChange={handleRowCheckboxChange(row._id)}
                          className="form-checkbox"
                        />
                      </TableCell>
                      {header.map(
                        (head: { header: string; accessor: string }) => (
                          <TableCell
                            key={head.accessor}
                            className={`p-2 text-gray-700 ${
                              head.accessor === "paymentStatus"
                                ? "capitalize"
                                : ""
                            }`}
                          >
                            {isValidDate(row[head.accessor])
                              ? moment(row[head.accessor]).format("DD/MM/YYYY")
                              : row[head.accessor]}
                          </TableCell>
                        ),
                      )}
                      {sheetName !== "Payment Receipts" && (
                        <TableCell className="flex items-center gap-2 p-2">
                          {sheetName === "Payment Reminders" && (
                            <Tooltip>
                              <TooltipTrigger>
                                <Link
                                  to={`/payment-receipts/${row._id}/add`}
                                  className="flex w-7 items-center justify-center rounded-full p-0"
                                >
                                  <Plus size={17} color="#374151" />
                                </Link>
                              </TooltipTrigger>
                              <TooltipContent>
                                <p>Add payment received</p>
                              </TooltipContent>
                            </Tooltip>
                          )}
                          <Tooltip>
                            <TooltipTrigger>
                              <Button
                                variant={"ghost"}
                                className="flex w-7 items-center justify-center rounded-full p-0"
                                onClick={() =>
                                  navigate(`${navigateUrl}/${row._id}#noedit`)
                                }
                              >
                                <Eye size={17} color="#374151" />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>View</p>
                            </TooltipContent>
                          </Tooltip>
                          <Tooltip>
                            <TooltipTrigger>
                              <Button
                                variant={"ghost"}
                                className="flex w-7 items-center justify-center rounded-full p-0"
                                onClick={() =>
                                  navigate(`${navigateUrl}/${row._id}`)
                                }
                              >
                                <PenBox size={17} color="#374151" />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>Edit</p>
                            </TooltipContent>
                          </Tooltip>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
            {totalPages > 1 && (
              <Pagination className="w-fit p-5">
                <PaginationContent className="flex items-center gap-1">
                  <PaginationItem>
                    <PaginationPrevious
                      className="cursor-pointer text-gray-600 hover:text-gray-800"
                      onClick={() => {
                        if (currentPage > 1) handlePageChange(currentPage - 1);
                      }}
                    />
                  </PaginationItem>
                  {currentPage > 4 && (
                    <PaginationItem>
                      <PaginationLink
                        onClick={(e) => {
                          e.preventDefault();
                          handlePageChange(1);
                        }}
                        className="text-primary hover:underline"
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                  )}
                  {currentPage > 4 && (
                    <PaginationItem>
                      <PaginationEllipsis className="text-gray-600" />
                    </PaginationItem>
                  )}
                  {Array.from({ length: totalPages - 1 }, (_, i) => i + 1)
                    .filter(
                      (page) =>
                        page >= currentPage - 1 && page <= currentPage + 4,
                    )
                    .map((page) => (
                      <PaginationItem key={page}>
                        <PaginationLink
                          className={`cursor-pointer ${
                            page === currentPage
                              ? "bg-primary text-white"
                              : "text-primary hover:underline"
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            handlePageChange(page);
                          }}
                          isActive={page === currentPage}
                        >
                          {page}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                  {currentPage < totalPages - 6 && (
                    <PaginationItem>
                      <PaginationEllipsis className="text-gray-600" />
                    </PaginationItem>
                  )}
                  {currentPage < totalPages - 5 && (
                    <PaginationItem>
                      <PaginationLink
                        onClick={(e) => {
                          e.preventDefault();
                          handlePageChange(totalPages - 1);
                        }}
                        className="text-primary hover:underline"
                      >
                        {totalPages - 1}
                      </PaginationLink>
                    </PaginationItem>
                  )}
                  <PaginationItem>
                    <PaginationNext
                      className="cursor-pointer text-gray-600 hover:text-gray-800"
                      onClick={() => {
                        if (currentPage < totalPages - 1)
                          handlePageChange(currentPage + 1);
                      }}
                    />
                  </PaginationItem>
                </PaginationContent>
              </Pagination>
            )}
          </div>
        ) : (
          <div className="mt-10 flex justify-center">No data to show</div>
        )}
      </div>
    </TooltipProvider>
  );
};

export default PaginatedTable;
