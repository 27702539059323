import { AlertDialog, AlertDialogContent } from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";

const ConfirmDeleteAccountModal = ({
  open,
  setOpen,
  onConfirm,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => void;
}) => {
  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent className="p-0">
        <div>
          <p className="border-b p-5 text-lg font-semibold">
            Are you sure you want to delete your account?
          </p>
          <div className="flex flex-col gap-5 p-5">
            <p className="text-sm text-gray-500">
              This action is irreversible. All your data will be lost.
            </p>
            <div className="flex justify-end space-x-2">
              <Button
                variant={"outline"}
                onClick={() => setOpen(false)}
                className="px-4 py-2 text-sm text-gray-500"
              >
                Cancel
              </Button>
              <Button
                variant={"destructive"}
                onClick={() => {
                  onConfirm();
                  setOpen(false);
                }}
              >
                Delete Account
              </Button>
            </div>
          </div>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ConfirmDeleteAccountModal;
