import { getOutstandingAmountByDueDate } from "@/api/user";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import CurrencyFormat from "react-currency-format";

const OutstandingByDate = () => {
  const [dueValue, setDueValue] = useState("dueToday");

  const { data }: { data: any; isLoading: boolean } = useQuery({
    queryKey: ["outstandingByDate", dueValue],
    queryFn: () => getOutstandingAmountByDueDate(dueValue),
    enabled: !!dueValue,
  });

  return (
    <div className="flex flex-col gap-4 rounded-xl border p-5">
      <div className="flex flex-wrap items-center justify-between gap-4">
        <p className="gap-5 whitespace-nowrap">
          Outstanding amount by Due Date
        </p>
        <div className="w-[200px]">
          <Select defaultValue="dueToday" onValueChange={(e) => setDueValue(e)}>
            <SelectTrigger>
              <SelectValue placeholder="Due today" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="dueToday">Due Today</SelectItem>
              <SelectItem value="dueTomorrow">Due Tomorrow</SelectItem>
              <SelectItem value="dueThisWeek">Due This Week</SelectItem>
              <SelectItem value="dueThisMonth">due This Month</SelectItem>
              <SelectItem value="overdue">Overdue</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
      <CurrencyFormat
        displayType={"text"}
        thousandSeparator={true}
        value={data?.totalOutstandingAmount}
        thousandSpacing="2s"
        prefix={"₹"}
        renderText={(value) => (
          <p className="text-3xl font-semibold">{value}</p>
        )}
      />
    </div>
  );
};

export default OutstandingByDate;
