import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Minus, Plus } from "lucide-react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormField,
  FormItem,
  FormControl,
  FormMessage,
} from "@/components/ui/form";
import { useContext, useState } from "react";
import { EmailReminderContext } from "@/context/email-reminder-context";
import TimePicker from "@/components/ui/time-picker/time-picker";

const formSchema = z.object({
  firstInterval: z.number().min(0, { message: "Interval must be at least 1" }),
  secondInterval: z.number().min(0, { message: "Interval must be at least 1" }),
});

interface FormValues {
  firstInterval: number;
  secondInterval: number;
}

const IntervalDays = () => {
  const { updateReminderMutation, reminderConfiguration } =
    useContext(EmailReminderContext);

  const [date, setDate] = useState<Date | undefined>(() => {
    const currentDate = new Date();
    currentDate.setHours(
      parseInt(reminderConfiguration?.time?.split(":")[0] || "0", 10),
      parseInt(reminderConfiguration?.time?.split(":")[1] || "0", 10),
      0, // seconds
      0, // milliseconds
    );
    return currentDate;
  });

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstInterval: reminderConfiguration?.firstIntervalDays || 0,
      secondInterval: reminderConfiguration?.secondIntervalDays || 0,
      time: reminderConfiguration?.time || "00:00", // Default time as string
    },
  });

  const onSubmit = (values: FormValues) => {
    updateReminderMutation.mutate({
      firstIntervalDays: values.firstInterval,
      secondIntervalDays: values.secondInterval,
      time: `${date?.getHours().toString().padStart(2, "0")}:${date?.getMinutes().toString().padStart(2, "0")}`,
    });
  };

  return (
    <div className="mx-auto max-w-3xl space-y-6 rounded-lg bg-white p-6 shadow-md">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
          <FormField
            control={form.control}
            name="firstInterval"
            render={({ field }) => (
              <FormItem>
                <div className="flex flex-col justify-between md:flex-row md:items-center">
                  <p className="text-lg font-semibold text-gray-700">
                    Interval days for 1st Reminder from due date
                  </p>
                  <div className="flex items-center gap-3">
                    <Button
                      variant={"destructive"}
                      onClick={(e) => {
                        e.preventDefault();
                        field.onChange(Math.max(0, field.value - 1));
                      }}
                      className="rounded-none px-3 py-2"
                    >
                      <Minus className="h-4 w-4" />
                    </Button>
                    <FormControl>
                      <Input
                        readOnly
                        className="w-16 border-gray-300 text-center"
                        {...field}
                        value={field.value}
                      />
                    </FormControl>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        field.onChange(field.value + 1);
                      }}
                      className="rounded-none px-3 py-2"
                    >
                      <Plus className="h-4 w-4" />
                    </Button>
                  </div>
                </div>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="secondInterval"
            render={({ field }) => (
              <FormItem>
                <div className="flex flex-col justify-between md:flex-row md:items-center">
                  <p className="text-lg font-semibold text-gray-700">
                    Interval days for 2nd Reminder from 1st reminder
                  </p>
                  <div className="flex items-center gap-3">
                    <Button
                      variant={"destructive"}
                      onClick={(e) => {
                        e.preventDefault();
                        field.onChange(Math.max(0, field.value - 1));
                      }}
                      className="rounded-none px-3 py-2"
                    >
                      <Minus className="h-4 w-4" />
                    </Button>
                    <FormControl>
                      <Input
                        readOnly
                        className="w-16 border-gray-300 text-center"
                        {...field}
                        value={field.value}
                      />
                    </FormControl>
                    <Button
                      className="rounded-none px-3 py-2"
                      onClick={(e) => {
                        e.preventDefault();
                        field.onChange(field.value + 1);
                      }}
                    >
                      <Plus className="h-4 w-4" />
                    </Button>
                  </div>
                </div>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex flex-col justify-between md:flex-row md:items-center">
            <p className="text-lg font-semibold text-gray-700">
              Time to send reminder
            </p>
            <TimePicker date={date} setDate={setDate} />
          </div>
          <div className="flex justify-end">
            <Button
              type="submit"
              className="min-w-[10rem]"
              disabled={updateReminderMutation.isPending}
              loading={updateReminderMutation.isPending}
            >
              Save Configuration
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default IntervalDays;
